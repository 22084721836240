import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  styled,
  InputBase,
  IconButton,
  ListItemButton,
  Badge,
  List,
  Button,
  MenuItem,
  Menu,
  Paper,
  Divider,
  Avatar,
  debounce,
  Tooltip,
  Grid,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { UserAddOutlined } from "@ant-design/icons";
import { getImages } from "../const";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessLogo,
  getBusinessName,
  getChatId,
  getGroupName,
  setBusinessUserId,
  setOpenChat,
  setOpenChatUserId,
  setSolutionId,
  setSolutionName,
  setSolutionProviderId,
  toggleChatData,
  toggleGroupSelected,
} from "../../redux/features/chatSlice";
import { toggleDrawer } from "../../redux/features/drawerSlice";
import { useFetchInboxSelectedSolutionListQuery } from "../../redux/api/Inbox/getInboxSelectedSolutionList";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { toast } from "react-toastify";
import { useFetchInboxBusinessUserListQuery } from "../../redux/api/SolutionProvider/Inbox/getBusinessUserList";
import InvitetoBSIColab from "./InvitetoBSIColab";
import Modals from "../Modals";
import { resetRefetchBusinessUserList, setRefetchTeamMemberList } from "../../redux/features/refetch";
import { useLocation, useNavigate } from "react-router-dom";

const AllBusinessUserList = (showToastMessage) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const selectedSolutionId = useSelector((state) => state.inbox.solutionId);
  const {    groupChatId } = useSelector((state) => state.chat);

  const open = useSelector((state) => state.drawer.open);
  const queryParams = new URLSearchParams(location.search);
  const RedirectSolutionId = queryParams.get("solutionId");
  const RedirectBusinessUserId = queryParams.get("businessUserId");
  const RedirectSolutionName = queryParams.get("solutionName");
  const RedirectSolutionProviderId = queryParams.get("solutionProviderId");

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));


  // searching Logic
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const {
    data: businessUserList,
    error: businessUserListError,
    isLoading: businessUserListLoading,
    refetch,
  } = useFetchInboxBusinessUserListQuery(
    {
      userId: userId,
      solutionId: RedirectSolutionId || selectedSolutionId,
    },
  );

  
   // set refetchBusinessUserList in reduxStore
  //  const refetchBusinessUserList = useSelector(
  //   (state) => state.refetch.refetchBusinessUserList
  // );
  // useEffect(() => {
  //   if (refetchBusinessUserList) {
  //     refetch().finally(() => {
  //       dispatch(resetRefetchBusinessUserList());
  //     });
  //   }
  // }, [refetchBusinessUserList, refetch, dispatch]);
  

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (businessUserList && query.length > 0) {
        const filtered = businessUserList.filter(user =>
          user?.userProfile?.business_name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredUsers(filtered);
      } else {
        setFilteredUsers(businessUserList || []);
      }
    }, 300),
    [businessUserList]
  );

 // Function to search business users based on the name
const handleSearchBusinessUser = (event) => {
  if (event.key === "Enter") {
    debouncedSearch(searchParams);
  }
};

const handleInputChange = (event) => {
  const inputValue = event.target.value.trim();
  if (!inputValue) {
    setSearchParams(""); 
    setFilteredUsers([]);
    refetch();
    return;
  }
  setSearchParams(inputValue);
};
  
const handleClearSearch = () => {
  setSearchParams("");
  setFilteredUsers([]);
  refetch(); // This assumes you're using React Query or a similar library
};

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDw = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const openStatus = Boolean(anchorElStatus);
  const handleStatusClick = (event) => {
    setAnchorElStatus(event.currentTarget);
  };
  const handleStatusClose = () => {
    setAnchorElStatus(null);
  };

  const [anchorElSolution, setAnchorElSolution] = React.useState(null);
  const openUser = Boolean(anchorElSolution);
  const handleBusinessUserClick = (event) => {
    setAnchorElSolution(event.currentTarget);
  };
  const handleBusinessUserClose = () => {
    setAnchorElSolution(null);
  };

  // InviteBsiCoLab Modal
  const [openInviteBsiCoLab, setOpenInviteBsiCoLab] = useState(false);
  const InviteBsiCoLabHandle = () => {
    setOpenInviteBsiCoLab(true);
  };
  const closeInviteBsiCoLab = () => {
    setOpenInviteBsiCoLab(false);
  };

  const { openChat, openSolutionList, chatData, solutionProviderId } =
    useSelector((state) => state.chat);
  const chatOpenHandle = (
    buttonIndex,
    solutionProviderId,
    solutionId,
    businessUserId,
    solutionName,
    businessName,
    businessLogo,
    chatId,
  ) => {
    dispatch(setSolutionProviderId(solutionProviderId));
    dispatch(setSolutionId(solutionId));
    dispatch(setBusinessUserId(businessUserId));
    dispatch(setSolutionName(solutionName));
    dispatch(getBusinessName(businessName));
    dispatch(getGroupName(businessName));
    dispatch(getBusinessLogo(businessLogo));
    dispatch(getChatId(chatId));
    dispatch(toggleGroupSelected(true));
    if (open) {
      dispatch(toggleDrawer());
    }
  };
  useEffect(()=>{
    if(RedirectBusinessUserId && RedirectSolutionId && RedirectSolutionName , RedirectSolutionProviderId){
      dispatch(setBusinessUserId(RedirectBusinessUserId));
      dispatch(setSolutionId(RedirectSolutionId));
      dispatch(setSolutionName(RedirectSolutionName));
      dispatch(setSolutionProviderId(RedirectSolutionProviderId));
      dispatch(toggleGroupSelected(true));
    }
  },[RedirectBusinessUserId , RedirectSolutionId , RedirectSolutionName])
  
  const refetchTeamMemberList = () => {
    dispatch(setRefetchTeamMemberList(true));
  };

  useEffect(() => {
    if (businessUserListLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [businessUserListLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "secondary.main",
            fontSize: "0.9rem",
            fontWeight: "700",
            pr: 1.5,
          }}
        >
          Business Chats
          {/* <div
          style={{
            position: "relative",
          }}
        >
          <Button
            id="basic-button1"
            aria-controls={openUser ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={openUser ? "true" : undefined}
            onClick={handleBusinessUserClick}
            sx={{
              p: "10px 0",
              minWidth: "10px",
              width: "100%",
              verticalAlign: "-5px",
              display: "flex",
              justifyContent: "space-between",
              color: "secondary.main",
            }}
          >
            <MoreVertOutlinedIcon
              sx={{
                fontSize: "1.2rem",
              }}
            />
          </Button>
          <Menu
            id="basic-menu1"
            anchorEl={anchorElSolution}
            open={openUser}
            onClose={handleBusinessUserClose}
            MenuListProps={{
              "aria-labelledby": "basic-button1",
            }}
            sx={{
              "& .MuiMenu-paper": {
                borderRadius: "4px",
                border: "none",
                minWidth: "210px",
                p: 0.5,
              },
            }}
          >
            <Paper
              sx={{
                width: 210,
                maxWidth: "100%",
                background: "#fff",
                borderRadius: "10px",
                borderBottom: "none",
                boxShadow: "none",
                p: 0.5,
              }}
            >
              <MenuItem
                onClick={handleBusinessUserClose}
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "lightSecondary.main",
                  py: 1,
                  px: 1.4,
                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                  "&.active": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              >
                All
              </MenuItem>
              <MenuItem
                onClick={handleBusinessUserClose}
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "lightSecondary.main",
                  py: 1,
                  px: 1.4,
                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                  "&.active": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              >
                Archived
              </MenuItem>
              <MenuItem
                onClick={handleBusinessUserClose}
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "lightSecondary.main",
                  py: 1,
                  px: 1.4,
                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                  "&.active": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              >
                Muted
              </MenuItem>
            </Paper>
          </Menu>
        </div> */}
        </Typography>
        <Button
          variant="none"
          sx={{
            textTransform: "inherit",
            // p: "10px 0 10px 10px",
            color: "primary.main",
            fontWeight: "600",
            "&:hover": {
              background: "transparent",
            },
            "@media (max-width:1600px) and (min-width: 1300px)": {
              fontSize: "0.9rem",
            },
            [theme.breakpoints.down("xl")]: {
              fontSize: "0.8rem",
            },
          }}
          onClick={InviteBsiCoLabHandle}
        >
          <UserAddOutlined
            style={{
              width: "15px",
              height: "20px",
              position: "relative",
            }}
          />
          Invite to BSI Co-lab
        </Button>
      </Box>
      <Box
        sx={{
          mb: 1.5,
          pl: 0,
          pr: 1.5,
        }}
      >
        <Search>
          <StyledInputBase
            placeholder="Search for solution"
            inputProps={{ "aria-label": "search" }}
            value={searchParams}
            onChange={handleInputChange}
            onKeyDown={handleSearchBusinessUser}
            autoFocus
          />
          {searchParams ? (
           <IconButton
            onClick={handleClearSearch}
            sx={{ position: "absolute", right: 0 }}
            >
            <CancelIcon sx={{ color: "lightSecondary.main" }} />
            </IconButton>
           ) : (
            <SearchIconWrapper
            sx={{ position: "absolute", right: 0 }}
            >
            <SearchIcon sx={{ color: "lightSecondary.main" }} />
            </SearchIconWrapper>
         )}
        </Search>
      </Box>

      <List
        component="nav"
        sx={{
          px: 0,
          py: 0,
          pr: 1.2,
          '& .MuiListItemButton-root': {
              py: 1.5,
          },
          "& .MuiButtonBase-root + .MuiButtonBase-root": {
            marginTop: "10px",
          },
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 214px)',
          '&::-webkit-scrollbar': {
              width: '6px',
              borderRadius: '4px'
          },
          '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
              borderRadius: '4px'
          },
          '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#9C9C9C',
              borderRadius: '4px'
          },
          [theme.breakpoints.down('lg')]: { 
              maxHeight: 'calc(100vh - 170px)',
          }
      }}

       
      >
        {/* <Grid
          mt={0.5}
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{
            mb: 0,
            "& .MuiButtonBase-root + .MuiButtonBase-root": {
              marginTop: "10px",
            },
            pl: 0,
            pr: 0.9,
            overflowY: openSolutionList ? "hidden" : "auto",
            maxHeight: "calc(100vh - 192px)",
            "&::-webkit-scrollbar": {
              width: "6px",
              borderRadius: "4px",
              display: "block",
              position: "absolute",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#9C9C9C",
              borderRadius: "4px",
            },
            [theme.breakpoints.down("lg")]: {
              maxHeight: "calc(100vh - 192px)",
            },
          }}
        > */}
          {filteredUsers.length > 0 ? 
              filteredUsers.map((user , index) => (
                <ListItemButton
                key={user._id}
                index={user._id}
                onClick={() =>
                  chatOpenHandle(
                    index,
                    user?.solution_provider_id?._id,
                    user?.solution_id,
                    user?.user_id?._id,
                    user?.solution_name,
                    user?.userProfile?.business_name,
                    user?.userProfile?.business_logo,
                    user?._id,
                  )
                }
                sx={{ 
                  px: 0.5,
                  borderRadius:'10px',
                  background: groupChatId === user?._id? 
                  "#d0e4f3" 
                  : "#ECF6FF", 
                  '&:hover':{
                    background: groupChatId === user?._id? 
                    "#d0e4f3" 
                    : "#ECF6FF", 
                  } }}
              >
                {/* Avatar Section */}
<ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            {/* <Badge
                                color="secondary"
                                overlap="circular"
                                badgeContent=" "
                                variant="dot"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: 'green.main',
                                        minWidth: '14px',
                                        height: '14px',
                                        borderRadius: '50%',
                                        border: 'solid 2px #ECF6FF'
                                    }
                                }}
                            > */}
                                {user?.userProfile?.business_logo ? (
                    <Avatar
                      src={user?.userProfile?.business_logo}
                      alt={`${user?.userProfile?.business_name || ""} logo`}
                      sx={{
                        color: 'success.main',
                        bgcolor: 'success.lighter',
                        width: '50px',
                        height: '50px'
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        color: '#fff',
                        bgcolor: 'lightSecondary.main',
                        width: '50px',
                        height: '50px'
                      }}
                    >
                      {user?.userProfile?.business_name ? (
                        user.userProfile?.business_name.slice(0, 2).toUpperCase()
                      ) : ""}
                    </Avatar>
                  )}
                            {/* </Badge> */}
                        </ListItemAvatar>

                {/* <Box
                  sx={{
                    backgroundColor: "extraLTheme.main",
                    borderRadius: "50%",
                    overflow: "hidden",
                    maxWidth: "20%",
                    width: "52px",
                    height: "52px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "12px",
                  }}
                >
                  {user?.userProfile?.business_logo ? (
                    <Avatar
                      src={user?.userProfile?.business_logo}
                      alt={`${user?.userProfile?.business_name || ""} logo`}
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "lightSecondary.main",
                        backgroundColor: "lightSecondary.main", 
                        fontSize: "1.2rem",
                        color: businessUserId === user?.user_id?._id ? "#fff" : "#fff",
                      }}
                    >
                      {user?.userProfile?.business_name ? (
                        user.userProfile?.business_name.slice(0, 2).toUpperCase()
                      ) : ""}
                    </Avatar>
                  )}
                </Box> */}
                 <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">
                                     <Tooltip 
                     title=
                     {user?.userProfile?.business_name ? 
                      `${user.userProfile.business_name.charAt(0).toUpperCase()}${user.userProfile.business_name.slice(1)}` 
                      : ""
                    }
                     >
                      {user?.userProfile?.business_name ? 
                        `${user.userProfile.business_name.charAt(0).toUpperCase()}${user.userProfile.business_name.slice(1)}` 
                        : ""
                      }
                    </Tooltip>
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'primary.main',
                                    maxWidth: '220px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >
                              {user?.latestMessage?.message} 
                              </Typography>}
                        />
                         <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px'
                                    }}
                                >
                                     {user?.latestMessage?.createdAt ? (
                                    isNaN(new Date(user.latestMessage.createdAt)) ? (
                                      "Invalid Date"
                                    ) : (
                                      new Date(user.latestMessage.createdAt).toLocaleTimeString("en-US", {
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      })
                                    )
                                  ) : (
                                    ""
                                  )}
                                </Typography>
                                <Box 
                                component="span"
                                    sx={{
                                        // bgcolor: 'primary.main',
                                        width: 24, height: 24,
                                        borderRadius: '50%',
                                        fontSize: '12px',
                                        color: '#fff',
                                        textAlign: 'center',
                                        lineHeight: '24px',
                                        display: groupChatId === user?._id? "none" : "block"
                                    }}></Box>
                            </Stack>
                        </ListItemText>
                {/* <Box
                  sx={{
                    display: "flex",
                    width:"70%",
                    maxWidth: "80%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width:'80%',
                      maxWidth: "100%",
                      justifyContent:'flex-start'
                    }}
                  >
                    <Typography
                    noWrap
                      variant="subtitle1"
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        color: businessUserId === user?.user_id?._id ? "black" : "black",
                        maxWidth: "80%",
                      }}
                    >
                     <Tooltip 
                     title=
                     {user?.userProfile?.business_name ? 
                      `${user.userProfile.business_name.charAt(0).toUpperCase()}${user.userProfile.business_name.slice(1)}` 
                      : ""
                    }
                     >
                      {user?.userProfile?.business_name ? 
                        `${user.userProfile.business_name.charAt(0).toUpperCase()}${user.userProfile.business_name.slice(1)}` 
                        : ""
                      }
                    </Tooltip>
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                          fontSize: "0.8rem",
                          color: businessUserId === user?.user_id?._id ? "primary.main" : "ltheme.main",
                          maxWidth:'80%'
                        }}
                      >
                        {user?.latestMessage?.message}
                      </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width:'10%',
                      maxWidth: "30%",
                      justifyContent:'flex-end'
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "0.75rem",
                        color: "grey.main",
                        marginTop: "2px",
                        // maxWidth: "100%",
                      }}
                    >
                      {user?.latestMessage?.createdAt ? (
                        isNaN(new Date(user.latestMessage.createdAt)) ? (
                          "Invalid Date"
                        ) : (
                          new Date(user.latestMessage.createdAt).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })
                        )
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Box>
                </Box> */}
              </ListItemButton>
              ))
              :
          businessUserList?.map((user, index) => (
            // <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
              <ListItemButton
                key={user._id}
                index={user._id}
                onClick={() =>
                  chatOpenHandle(
                    index,
                    user?.solution_provider_id?._id,
                    user?.solution_id,
                    user?.user_id?._id,
                    user?.solution_name,
                    user?.userProfile?.business_name,
                    user?.userProfile?.business_logo,
                    user?._id,
                  )
                }
                sx={{ 
                  px: 0.5,
                  borderRadius:'10px',
                  background: groupChatId === user?._id? 
                  "#d0e4f3" 
                  : "#ECF6FF", 
                  '&:hover':{
                    background: groupChatId === user?._id? 
                    "#d0e4f3" 
                    : "#ECF6FF", 
                  } }}
              >
                {/* Avatar Section */}
<ListItemAvatar
                            sx={{
                                width: "56px"
                            }}
                        >
                            {/* <Badge
                                color="secondary"
                                overlap="circular"
                                badgeContent=" "
                                variant="dot"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: 'green.main',
                                        minWidth: '14px',
                                        height: '14px',
                                        borderRadius: '50%',
                                        border: 'solid 2px #ECF6FF'
                                    }
                                }}
                            > */}
                                {user?.userProfile?.business_logo ? (
                    <Avatar
                      src={user?.userProfile?.business_logo}
                      alt={`${user?.userProfile?.business_name || ""} logo`}
                      sx={{
                        color: 'success.main',
                        bgcolor: 'success.lighter',
                        width: '50px',
                        height: '50px'
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        color: '#fff',
                        bgcolor: 'lightSecondary.main',
                        width: '50px',
                        height: '50px'
                      }}
                    >
                      {user?.userProfile?.business_name ? (
                        user.userProfile?.business_name.slice(0, 2).toUpperCase()
                      ) : ""}
                    </Avatar>
                  )}
                            {/* </Badge> */}
                        </ListItemAvatar>

                {/* <Box
                  sx={{
                    backgroundColor: "extraLTheme.main",
                    borderRadius: "50%",
                    overflow: "hidden",
                    maxWidth: "20%",
                    width: "52px",
                    height: "52px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "12px",
                  }}
                >
                  {user?.userProfile?.business_logo ? (
                    <Avatar
                      src={user?.userProfile?.business_logo}
                      alt={`${user?.userProfile?.business_name || ""} logo`}
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "lightSecondary.main",
                        backgroundColor: "lightSecondary.main", 
                        fontSize: "1.2rem",
                        color: businessUserId === user?.user_id?._id ? "#fff" : "#fff",
                      }}
                    >
                      {user?.userProfile?.business_name ? (
                        user.userProfile?.business_name.slice(0, 2).toUpperCase()
                      ) : ""}
                    </Avatar>
                  )}
                </Box> */}
                 <ListItemText
                            sx={{
                                pl: '10px',
                                width: "60%"
                            }}
                            primary={
                                <Typography noWrap
                                    sx={{
                                        fontSize: "1rem",
                                        fontWeight: '600',
                                        color: 'secondary.main',
                                        lineHeight: '22px',
                                        maxWidth: '220px',
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '110px'
                                        }
                                    }}
                                    variant="h6">
                                     <Tooltip 
                     title=
                     {user?.userProfile?.business_name ? 
                      `${user.userProfile.business_name.charAt(0).toUpperCase()}${user.userProfile.business_name.slice(1)}` 
                      : ""
                    }
                     >
                      {user?.userProfile?.business_name ? 
                        `${user.userProfile.business_name.charAt(0).toUpperCase()}${user.userProfile.business_name.slice(1)}` 
                        : ""
                      }
                    </Tooltip>
                                </Typography>}
                            secondary={<Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'primary.main',
                                    maxWidth: '130px',
                                    [theme.breakpoints.down('sm')]: {
                                        maxWidth: '110px'
                                    }
                                }}
                            >
                              {user?.latestMessage?.message} 
                              </Typography>}
                        />
                         <ListItemText
                            sx={{
                                width: "calc(40% - 56px)"
                            }}
                        >
                            <Stack alignItems="flex-end">
                                <Typography
                                    variant="body2" noWrap
                                    sx={{
                                        color: "ltheme.main",
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        mb: '5px',
                                    }}
                                >
                                     {user?.latestMessage?.createdAt ? (
                                    isNaN(new Date(user.latestMessage.createdAt)) ? (
                                      "Invalid Date"
                                    ) : (
                                      new Date(user.latestMessage.createdAt).toLocaleTimeString("en-US", {
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      })
                                    )
                                  ) : (
                                    ""
                                  )}
                                </Typography>
                                <Box component="span"
                                    sx={{
                                        // bgcolor: 'primary.main',
                                        width: 24, height: 24,
                                        borderRadius: '50%',
                                        fontSize: '12px',
                                        color: '#fff',
                                        textAlign: 'center',
                                        lineHeight: '24px',
                                        display: groupChatId === user?._id? "none" : "block"
                                    }}></Box>
                            </Stack>
                        </ListItemText>
                {/* <Box
                  sx={{
                    display: "flex",
                    width:"70%",
                    maxWidth: "80%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width:'80%',
                      maxWidth: "100%",
                      justifyContent:'flex-start'
                    }}
                  >
                    <Typography
                    noWrap
                      variant="subtitle1"
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: 600,
                        color: businessUserId === user?.user_id?._id ? "black" : "black",
                        maxWidth: "80%",
                      }}
                    >
                     <Tooltip 
                     title=
                     {user?.userProfile?.business_name ? 
                      `${user.userProfile.business_name.charAt(0).toUpperCase()}${user.userProfile.business_name.slice(1)}` 
                      : ""
                    }
                     >
                      {user?.userProfile?.business_name ? 
                        `${user.userProfile.business_name.charAt(0).toUpperCase()}${user.userProfile.business_name.slice(1)}` 
                        : ""
                      }
                    </Tooltip>
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                          fontSize: "0.8rem",
                          color: businessUserId === user?.user_id?._id ? "primary.main" : "ltheme.main",
                          maxWidth:'80%'
                        }}
                      >
                        {user?.latestMessage?.message}
                      </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width:'10%',
                      maxWidth: "30%",
                      justifyContent:'flex-end'
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "0.75rem",
                        color: "grey.main",
                        marginTop: "2px",
                        // maxWidth: "100%",
                      }}
                    >
                      {user?.latestMessage?.createdAt ? (
                        isNaN(new Date(user.latestMessage.createdAt)) ? (
                          "Invalid Date"
                        ) : (
                          new Date(user.latestMessage.createdAt).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })
                        )
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Box>
                </Box> */}
              </ListItemButton>
            // </Grid>
          ))}
        {/* </Grid> */}
      </List>
      {/* </Grid> */}
      {/* </Grid> */}
      <Modals
        open={openInviteBsiCoLab}
        handleClose={closeInviteBsiCoLab}
        modalWidth={500}
      >
        <InvitetoBSIColab
          showToastMessage={showToastMessage}
          handleClose={closeInviteBsiCoLab}
          refetchTeamMemberList={refetchTeamMemberList}
        />
      </Modals>
    </>
  );
};

export default AllBusinessUserList;
