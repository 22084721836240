import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chatData: false,
    openChat: false,
    openChatInfo: false,
    openSolutionList: false,
    openProfileMedia: false,
    openProfileDocument: false,
    chatSelected: false,
    openGroupChat: false,
    openInviteToBSIColab: false,
    openNewChat: false,
    threadChat: false,
    createGroup: false,
    solutionProviderId: null,
    solutionId: null,
    solutionName: null,
    solutionLogo: "",
    businessUserId: null,
    groupSelected: false,
    businessName: "",
    businessLogo: "",
    groupName: "",
    groupChatId: null,
    
};

const chatSlice = createSlice({
    name:'chat',
    initialState,
    reducers: {
        toggleChatData(state) {
            state.chatData = !state.chatData;
        },
        setOpenChat(state, action) {
            state.openChat = action.payload;
        },
        toggleOpenChatInfo(state) {
            state.openChatInfo = !state.openChatInfo;
        },
        toggleGroupSelected(state , action) {
            state.groupSelected = action.payload;
        },
        toggleOpenSolutionList(state) {
            state.openSolutionList = !state.openSolutionList;
        },
        toggleOpenProfileMedia(state) {
            state.openProfileMedia = !state.openProfileMedia;
        },
        toggleOpenProfileDocument(state) {
            state.openProfileDocument = !state.openProfileDocument;
        },
        setChatSelected(state, action) {
            state.chatSelected = action.payload;
        },
        setOpenGroupChat(state, action) {
            state.openGroupChat = action.payload;
        },
        toggleOpenInviteToBSIColab(state) {
            state.openInviteToBSIColab = !state.openInviteToBSIColab;
        },
        toggleOpenNewChat(state) {
            state.openNewChat = !state.openNewChat;
        },
        toggleThreadChat(state) {
            state.threadChat = !state.threadChat;
        },
        toggleCreateGroup(state) {
            state.createGroup = !state.createGroup;
        },
        setSolutionProviderId(state , action){
            state.solutionProviderId = action.payload
        },
        setSolutionId(state , action){
            state.solutionId = action.payload
        },
        setSolutionName(state , action){
            state.solutionName = action.payload
        },
        getSolutionLogo(state , action){
            state.solutionLogo = action.payload
        },
        setBusinessUserId(state , action){
            state.businessUserId = action.payload
        },
        getBusinessName(state , action){
            state.businessName = action.payload
        },
        getBusinessLogo(state , action){
            state.businessLogo = action.payload
        },
        getGroupName(state , action){
            state.groupName = action.payload
        },
        getChatId(state , action){
            state.groupChatId = action.payload
        },
        clearBusinessUserId(state) {
            state.businessUserId = null;
        },
        clearSolutionId(state) {
            state.solutionId = null;
        }
    },
});

export const {
    toggleChatData,
    setOpenChat,
    toggleOpenChatInfo,
    toggleOpenSolutionList,
    toggleOpenProfileMedia,
    toggleOpenProfileDocument,
    setChatSelected,
    setOpenGroupChat,
    toggleOpenInviteToBSIColab,
    toggleOpenNewChat,
    toggleThreadChat,
    toggleCreateGroup,
    setSolutionProviderId,
    setSolutionId,
    setBusinessUserId,
    setSolutionName,
    toggleGroupSelected,
    clearBusinessUserId,
    clearSolutionId,
    getBusinessName,
    getBusinessLogo,
    getSolutionLogo,
    getGroupName,
    getChatId
} = chatSlice.actions;

export default chatSlice.reducer;