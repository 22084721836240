import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  Menu,
  Paper,
  ListItemButton,
  List,
  ListItem,
  IconButton,
  ListItemAvatar,
  Modal,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import StarIcon from "@mui/icons-material/Star";
import { getImages } from "../../commonComponents/const";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useFetchDriveFolderListQuery } from "../../redux/api/BsiDrive/getDriveFolderList";
import { toast } from "react-toastify";
import NewFolderModal from "./NewFolderModal";
import RenameModal from "./RenameModal";
import MoveModal from "./MoveModal";
import ShareModal from "./ShareModal";
import ShareSelectedBoardModal from "./ShareSelectedBoardModal";
import ShareSolutionChatModal from "./ShareSolutionChatModal";
import Modals from "../Modals";
import DeleteFolderAlert from "./DeleteFolderAlert";
import { useFetchDriveSubFolderListQuery } from "../../redux/api/BsiDrive/getDriveSubFolderList";
import CustomLoader from "../../utils/Loader/CustomLoader";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { resetRefetchSubFolder } from "../../redux/features/refetch";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { KeyboardArrowRight } from "@mui/icons-material";

const SubDriveFolder = forwardRef(
  (
    { searchValue, showToastMessage, parentFolderId, parentFolderName },
    ref
  ) => {
    // function for file avatar
    function getIconForFileType(fileType) {
      switch (fileType.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "png":
          return getImages("image-icon.webp"); // Path to your image icon
        case "gif":
          return getImages("gifIcon.jfif");
        case "pdf":
          return getImages("pdf_icon.png"); // Path to your PDF icon
        case "docx":
          return getImages("docxIcon.png"); // Path to your PDF icon
        case "doc":
          return getImages("doc_icon.png"); // Path to your PDF icon
        case "mp4":
          return getImages("mp4.png");
        case "avi":
          return getImages("avi.png");
        case "mov":
          return getImages("mov.png");
        case "wmv":
          return getImages("wmv.png");
        case "mp3":
          return getImages("mp3.png");
        case "wav":
          return getImages("wav.png");
        case "ogg":
          return getImages("ogg.png");
        default:
          return getImages("image-icon.webp"); // Default icon for other file types
      }
    }

    const theme = useTheme();
    const dispatch = useDispatch();
    const userId = localStorage.getItem("userId");

    const navigate = useNavigate();

    const isDrawerOpen = useSelector((state) => state.drawer.open);

    const [anchorElFolderDropdown, setAnchorElFolderDropdown] = useState(null);
    const openFolderDropdown = Boolean(anchorElFolderDropdown);

    const [anchorElOtherOptions, setAnchorElOtherOptions] = useState(null);
    const [anchorFolderOtherOptions, setAnchorFolderOtherOptions] =
      useState(null);
    const openOtherOptions = Boolean(anchorElOtherOptions);
    const openFolderOtherOptions = Boolean(anchorFolderOtherOptions);

    const [documentId, setDocumentId] = useState("");
    const [subFolderId, setSubFolderId] = useState("");
    const [subFolderName, setSubFolderName] = useState("");
    const [documentName, setDocumentName] = useState("");
    const [documentUrl, setDocumentUrl] = useState("");
    const [history, setHistory] = useState([
      { id:subFolderId , name: parentFolderName },
    ]);
    const [isSubFolderClicked ,  setIsSubFolderClicked] = useState(false);

    const handleOtherOptionsClick = (
      event,
      documentId,
      documentName,
      documentStatus,
      documentUrl
    ) => {
      if (documentStatus === false) {
        setAnchorElOtherOptions(event.currentTarget);
        setDocumentUrl(documentUrl);
      } else {
        setAnchorFolderOtherOptions(event.currentTarget);
      }
      setDocumentId(documentId);
      setDocumentName(documentName);
    };

    const handleOtherOptionsClose = () => {
      setAnchorElOtherOptions(null);
      setAnchorFolderOtherOptions(null);
    };

    const [openNewFolder, setOpenNewFolder] = useState(false);
    const handleNewFolder = () => {
      setOpenNewFolder(true);
    };

    const closeNewFolder = () => {
      setOpenNewFolder(false);
    };

    const [openRename, setOpenRename] = useState(false);
    const handleRename = () => {
      setOpenRename(true);
    };

    const closeRename = () => {
      setOpenRename(false);
    };

    const [openShare, setOpenShare] = useState(false);
    const handleShare = () => {
      setOpenShare(true);
    };

    const closeShare = () => {
      setOpenShare(false);
    };

    const [openSelectedBoard, setOpenSelectedBoard] = useState(false);
    const selectedBoardOpen = () => {
      setOpenSelectedBoard(true);
      setOpenShare(false);
    };

    const closeSelectedBoard = () => {
      setOpenSelectedBoard(false);
    };

    const [openShareChat, setOpenShareChat] = useState(false);
    const shareChatOpen = () => {
      setOpenShareChat(true);
      setOpenSelectedBoard(false);
    };

    const closeShareChat = () => {
      setOpenShareChat(false);
    };

    const [openDeleteFolderAlert, setOpenDeleteFolderAlert] = useState(false);
    const deleteFolderAlert = () => {
      setOpenDeleteFolderAlert(true);
    };
    const goBackFromAlertHandle = () => {
      setOpenDeleteFolderAlert(false);
    };

    // Open Move
    const [openMove, setOpenMove] = useState(false);

    const handleMove = () => {
      setOpenMove(true);
    };
    const closeMove = () => {
      setOpenMove(false);
    };

    const parentId = subFolderId || parentFolderId;
    useEffect(() => {
      localStorage.setItem("parentId", parentId);
    });
    const {
      data: subFolderList,
      error: isDriveSubFolderError,
      isLoading: isDriveSubFolderLoading,
      refetch: refetchSubFolder,
    } = useFetchDriveSubFolderListQuery({ userId, parentId, searchValue });
    const handleSubFolderOpen = (subFolderId, documentName) => {
      setHistory((prev) => [...prev, { id: subFolderId, name: documentName }]);
      setSubFolderId(subFolderId);
      setIsSubFolderClicked(true)

      localStorage.setItem("subFolderId", subFolderId);
      setSubFolderName(documentName);
      refetchSubFolder().then(()=>{
        setTimeout(()=>{
          setIsSubFolderClicked(false)
        },100)
      });
      
    };

    const handleBack = () => {
      // if (history.length > 0) {
      let previous = {};
      if (history.length < 2) {
        previous = history[history.length - 1];
      } else {
        // Return the second-to-last index
        previous = history[history.length - 2];
      }

      setHistory((prev) => prev.slice(0, -1));
      if (history.length == 1) {
        localStorage.removeItem("parentId");
        navigate("/bsi-drive");
      } else {
        if (history.length > 1) {
          setSubFolderId(previous.id);
          setSubFolderName(previous.name);
          refetchSubFolder();
        } else {
          setSubFolderId(parentFolderId);
          setSubFolderName(parentFolderName);
          refetchSubFolder();
        }
      }
    };
    // store handleBack and subFolder ID in using useRef so that we can use it in bsi-sub-drive
    useImperativeHandle(ref, () => ({
      handleBack,
    }));

    // store refetchSubFolder in redux store
    const refetchSubDocument = useSelector(
      (state) => state.refetch.refetchSubFolder
    );
    useEffect(() => {
      if (refetchSubDocument) {
        refetchSubFolder().finally(() => {
          dispatch(resetRefetchSubFolder());
        });
      }
    }, [refetchSubDocument, refetchSubFolder, dispatch]);

    const handleDoubleTap = (documentName, documentUrl) => {
      setTimeout(() => {
        handleDownload();
      }, 100);
    };

    //   Download logic
    const handleDownload = async () => {
      try {
        // Fetch the file from the server
        const response = await fetch(documentUrl);

        // Check if the response is ok
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // Create a blob from the response
        const blob = await response.blob();

        // Create a link element
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        // Set the href of the link to the blob URL
        link.href = url;

        // Choose the filename for the downloaded file
        link.download = documentName || "downloaded-file"; // Ensure documentName is a string

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up and remove the link from the DOM
        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url); // Free up memory
        }, 100); // Delay removal to ensure click event has been processed
      } catch (error) {
        console.error("Failed to download file:", error);
      }
    };

    //  show loader
    useEffect(() => {
      if (isDriveSubFolderLoading) {
        dispatch(showLoader());
      } else {
        dispatch(hideLoader());
      }
    }, [isDriveSubFolderLoading, dispatch]);

    const parentData = JSON.parse(localStorage.getItem("document_path")) || [];

    const handlePreviousClick = (item, index) => {
      // console.log(item,"item");
      // if (item.parent_id != undefined) {
      //     localStorage.setItem("parentId",item.parent_id)

      // } else {

      //   localStorage.setItem("parentId",item.parent_id)
      // }
      setHistory((prev) => prev.slice(0, index + 1));
      setSubFolderId(item.id);
      setSubFolderName(item.name);
      refetchSubFolder();
    };

    return (
      <>
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              color: "secondary.main",
              fontSize: "1.1rem",
              fontWeight: "700",
              mb: 1,
            }}
          >
            {history.length > 0 && (
              <Link
                to="/bsi-drive"
                style={{ textDecoration: "none" }}
                className="ud-link"
                onClick={(e) => {
                  handlePreviousClick({ id: "null", name: "All Folders" }, 0);
                  localStorage.removeItem("parentId");
                }}
              >
                All Folders
              </Link>
            )}
            {history?.map((item, pos) => {
              return pos == history?.length - 1 ? (
                <>
                  <KeyboardArrowRight />
                  {subFolderName || parentFolderName}{" "}
                </>
              ) : (
                <>
                  <KeyboardArrowRight />
                  <Link
                    to="/bsi-sub-drive"
                    style={{
                      textDecoration: "none",
                      color: "#073985 !important",
                    }}
                    className="ud-link"
                    onClick={(e) => handlePreviousClick(item, pos)}
                  >
                    {item.name}
                  </Link>{" "}
                </>
              );
            })}
          </Typography>
          <Box
            sx={{
              flexWrap: "wrap",
              gap: 2,
              display: "inline-flex",
              width: "100%",
            }}
          >
            <ListItemButton
              onClick={handleNewFolder}
              sx={{
                border: "solid 1px #A2C2F1",
                minWidth: "240px",
                maxWidth: "240px",
                background: "#ECF6FF",
                padding: "14px 20px 14px 20px",
                borderRadius: "10px",
                display: "inline-block",
                width: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "lightSecondary.main",
                fontWeight: "600",
                fontSize: "1rem",
                flexGrow: "inherit",
                "@media (max-width:1600px) and (min-width:1200px)": {
                  minWidth: "240px",
                  maxWidth: "100%",
                  width: "calc(20% - 15px)",
                  padding: "14px 13px",
                },
                [theme.breakpoints.down("md")]: {
                  minWidth: "auto",
                  maxWidth: "100%",
                  width: "calc(50% - 10px)",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AddOutlinedIcon
                  sx={{
                    mr: 1.2,
                    verticalAlign: "0px",
                  }}
                />
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    maxWidth: isDrawerOpen ? "120px" : "150px",
                    "@media (max-width:1400px) and (min-width:1200px)": {
                      maxWidth: isDrawerOpen ? "100px" : "140px",
                    },
                    color: "lightSecondary.main",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  Create New Folder
                </Typography>
              </Box>
            </ListItemButton>
            {subFolderList?.subFolder?.map((subDocument, index) => {
              return subDocument.isDocument === true ? (
                <ListItemButton
                  key={subDocument._id + subDocument.name}
                  index={index}
                  sx={{
                    border: "solid 1px #A2C2F1",
                    minWidth: "240px",
                    maxWidth: "240px",
                    background: "#ECF6FF",
                    padding: "14px 20px 14px 20px",
                    borderRadius: "10px",
                    display: "inline-block",
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: "lightSecondary.main",
                    fontWeight: "600",
                    fontSize: "1rem",
                    flexGrow: "inherit",
                    "@media (max-width:1600px) and (min-width:1200px)": {
                      minWidth: "240px",
                      maxWidth: "100%",
                      width: "calc(20% - 15px)",
                      padding: "14px 13px",
                    },
                    [theme.breakpoints.down("md")]: {
                      minWidth: "auto",
                      maxWidth: "100%",
                      width: "calc(50% - 10px)",
                    },
                    [theme.breakpoints.down("sm")]: {
                      width: "100%",
                    },
                  }}
                >
                  <Box
                    noWrap
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      zIndex: 0,
                    }}
                    onClick={() =>
                      handleSubFolderOpen(subDocument._id, subDocument.name)
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FolderIcon
                        sx={{
                          mr: 1.2,
                          verticalAlign: "0px",
                        }}
                      />
                      <Typography
                        variant="h6"
                        noWrap
                        sx={{
                          maxWidth: isDrawerOpen ? "120px" : "150px",
                          "@media (max-width:1400px) and (min-width:1200px)": {
                            maxWidth: isDrawerOpen ? "80px" : "120px",
                          },
                          "@media (max-width:1200px) and (min-width: 900px)": {
                            maxWidth: isDrawerOpen ? "80px" : "100px",
                          },
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          fontSize: "1rem",
                        }}
                      >
                        {subDocument.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    id={subDocument._id}
                    aria-controls={
                      openFolderOtherOptions ? "basic-menu1" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFolderOtherOptions ? "true" : undefined}
                    onClick={(event) =>
                      handleOtherOptionsClick(
                        event,
                        subDocument._id,
                        subDocument.name,
                        subDocument.isDocument
                      )
                    }
                    sx={{
                      p: "1.3rem 1rem",
                      minWidth: "10px",
                      // width: "100%",
                      position: "absolute",
                      right: 0,
                      verticalAlign: "-5px",
                      display: "flex",
                      justifyContent: "space-between",
                      color: "secondary.main",
                      // background: "transparent",
                      zIndex: 2,
                      borderRadius: "0 10px 10px 0 ",
                    }}
                  >
                    <MoreVertOutlinedIcon
                      sx={{ verticalAlign: "0px", fontSize: "1.1rem" }}
                    />
                  </Button>
                  {index === index && (
                    <div style={{ position: "relative" }}>
                      <Menu
                        id={subDocument._id}
                        key={subDocument._id}
                        anchorEl={anchorFolderOtherOptions}
                        open={openFolderOtherOptions}
                        onClose={handleOtherOptionsClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          top: "2px",
                          left: "60px !important",
                          "&.MuiMenu-paper": {
                            borderRadius: "4px",
                            border: "none",
                            minWidth: "260",
                            p: 0.5,
                          },
                        }}
                      >
                        <Paper
                          sx={{
                            width: 260,
                            maxWidth: "100%",
                            background: "#fff",
                            borderRadius: "10px",
                            borderBottom: "none",
                            boxShadow: "none",
                            p: 0.5,
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              handleOtherOptionsClose();
                              handleShare();
                            }}
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              lineHeight: "20px",
                              color: "lightSecondary.main",
                              py: 1,
                              px: 1.4,
                              "&:hover": {
                                backgroundColor: "extraLTheme.main",
                              },
                              "&.active": {
                                backgroundColor: "primary.main",
                                color: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <PersonAddAltOutlinedIcon sx={{ mr: 1 }} /> Share
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleRename();
                              handleOtherOptionsClose();
                            }}
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              lineHeight: "20px",
                              color: "lightSecondary.main",
                              py: 1,
                              px: 1.4,
                              "&:hover": {
                                backgroundColor: "extraLTheme.main",
                              },
                              "&.active": {
                                backgroundColor: "primary.main",
                                color: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <BorderColorOutlinedIcon sx={{ mr: 1 }} /> Rename
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              deleteFolderAlert();
                              handleOtherOptionsClose();
                            }}
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              lineHeight: "20px",
                              color: "lightSecondary.main",
                              py: 1,
                              px: 1.4,
                              "&:hover": {
                                backgroundColor: "extraLTheme.main",
                              },
                              "&.active": {
                                backgroundColor: "primary.main",
                                color: "#fff",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <DeleteOutlineOutlinedIcon sx={{ mr: 1 }} /> Delete
                          </MenuItem>
                        </Paper>
                      </Menu>
                    </div>
                  )}
                </ListItemButton>
              ) : (
                <></>
              );
            })}
          </Box>

          <Box
            sx={{
              mt: 4,
              mb: 3,
            }}
          >
            {/* <Typography variant='h6'
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        color: 'secondary.main',
                        fontSize: "1.1rem",
                        fontWeight: "700",
                        mb: 1
                    }}
                >
                    <ArrowDropDownIcon
                        sx={{
                            fontSize: "1.2rem",
                        }}
                    />
                    All Files
                   
                </Typography> */}
            <List
              sx={{
                flexWrap: "wrap",
                gap: 2,
                display: "inline-flex",
                p: 0,
              }}
            >
              {subFolderList?.subFolder?.length > 0 ? (
                subFolderList.subFolder
                  .filter((subDocument) => subDocument.isDocument === false) // Filter out items that are not documents
                  .map((subDocument, index) => {
                    const fileExtension = subDocument.name
                      ? subDocument.name.split(".").pop().toLowerCase()
                      : "";
                    const isImage = ["jpg", "jpeg", "png"].includes(
                      fileExtension
                    );
                    const isPDF = fileExtension === "pdf";
                    const isGif = fileExtension === "gif";
                    const isDocx = fileExtension === "docx";
                    const isDoc = fileExtension === "doc";
                    const isMp4 = fileExtension === "mp4";
                    const isAvi = fileExtension === "avi";
                    const isMov = fileExtension === "mov";
                    const isWmv = fileExtension === "wmv";
                    const isMp3 = fileExtension === "mp3";
                    const isWav = fileExtension === "wav";
                    const isOgg = fileExtension === "ogg";

                    return (
                      <ListItem
                        key={subDocument.id}
                        onDoubleClick={() => handleDoubleTap()}
                        onClick={() => {
                          setDocumentName(subDocument.name);
                          setDocumentUrl(subDocument.s3Url);
                        }}
                        sx={{
                          border: "solid 1px #A2C2F1",
                          minWidth: "240px",
                          maxWidth: "240px",
                          background: "#ECF6FF",
                          padding: 0,
                          borderRadius: "10px",
                          display: "inline-block",
                          width: "auto",
                          color: "lightSecondary.main",
                          fontWeight: "600",
                          fontSize: "1rem",
                          overflow: "hidden",
                          flexGrow: "inherit",
                          "@media (max-width:1600px) and (min-width:1200px)": {
                            minWidth: "240px",
                            maxWidth: "100%",
                            width: "calc(20% - 15px)",
                          },
                          [theme.breakpoints.down("md")]: {
                            minWidth: "auto",
                            maxWidth: "100%",
                            width: "calc(50% - 10px)",
                          },
                          [theme.breakpoints.down("sm")]: {
                            width: "100%",
                          },
                        }}
                      >
                        <Box>
                          <img
                            style={{
                              width: "100%",
                              display: "block",
                              height: "180px",
                              padding: "2rem 4rem",
                              objectFit: isImage ? "fill" : "contain",
                              backgroundColor: isPDF
                                ? "#f0f0f0"
                                : "transparent",
                              mixBlendMode: "multiply",
                            }}
                            src={
                              isImage
                                ? getImages("image-icon.webp")
                                : isPDF
                                ? getImages("pdfImage.webp") // Replace with your PDF icon path
                                : isGif
                                ? getImages("gifIcon.jfif")
                                : isDocx
                                ? getImages("docxIcon.png")
                                : isDoc
                                ? getImages("doc_icon.png")
                                : isMp4
                                ? getImages("mp4.png")
                                : isAvi
                                ? getImages("avi.png")
                                : isMov
                                ? getImages("mov.png")
                                : isWav
                                ? getImages("wav.png")
                                : isMp3
                                ? getImages("mp3.png")
                                : // : isWav
                                // ? getImages("wav.png")
                                isOgg
                                ? getImages("ogg.png")
                                : getImages("image-icon.webp") // Replace with your default icon path
                            }
                            alt={subDocument.name || "Document Image"}
                          />
                        </Box>
                        <Box
                          sx={{
                            borderTop: "solid 1px #A2C2F1",
                            background: "#ECF6FF",
                            padding: "14px 10px 14px 10px",
                            borderRadius: "0 0 10px 10px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "lightSecondary.main",
                            fontWeight: "600",
                            fontSize: "1rem",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ListItemAvatar
                              sx={{
                                textAlign: "center",
                                minWidth: "30px",
                              }}
                            >
                              <img
                                style={{
                                  maxWidth: "26px",
                                  maxHeight: "26px",
                                  display: "block",
                                }}
                                src={
                                  subDocument.name
                                    ? getIconForFileType(
                                        subDocument.name.split(".").pop()
                                      )
                                    : "path/to/default/icon.png"
                                }
                              />
                            </ListItemAvatar>
                            <Typography
                              variant="h6"
                              noWrap
                              sx={{
                                maxWidth: "120px",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                fontSize: "1rem",
                              }}
                            >
                              {subDocument.name}
                            </Typography>
                          </Box>
                          <Button
                            id={subDocument._id}
                            aria-controls={
                              openOtherOptions ? "basic-menu2" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              openOtherOptions ? "true" : undefined
                            }
                            onClick={(event) =>
                              handleOtherOptionsClick(
                                event,
                                subDocument._id,
                                subDocument.name,
                                subDocument.isDocument,
                                subDocument.s3Url
                              )
                            }
                            sx={{
                              p: "1.3rem 1rem",
                              minWidth: "10px",
                              position: "absolute",
                              right: 0,
                              verticalAlign: "-5px",
                              display: "flex",
                              justifyContent: "space-between",
                              color: "secondary.main",
                              zIndex: 2,
                              borderRadius: "0 10px 10px 0 ",
                            }}
                          >
                            <MoreVertOutlinedIcon
                              sx={{
                                verticalAlign: "0px",
                                fontSize: "1.1rem",
                              }}
                            />
                          </Button>
                          {index == index && (
                            <div style={{ position: "relative" }}>
                              <Menu
                                id={subDocument._id}
                                anchorEl={anchorElOtherOptions}
                                open={openOtherOptions}
                                onClose={handleOtherOptionsClose}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button1",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                sx={{
                                  top: "2px",
                                  left: "60px !important",
                                  "&.MuiMenu-paper": {
                                    borderRadius: "4px",
                                    border: "none",
                                    minWidth: "260",
                                    p: 0.5,
                                  },
                                }}
                              >
                                <Paper
                                  sx={{
                                    width: 260,
                                    maxWidth: "100%",
                                    background: "#fff",
                                    borderRadius: "10px",
                                    borderBottom: "none",
                                    boxShadow: "none",
                                    p: 0.5,
                                  }}
                                >
                                  {/* <MenuItem
                                onClick={() => {
                                  handleOtherOptionsClose();
                                  handlePreview(
                                    subDocument.id,
                                    subDocument.name
                                  );
                                }}
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                  color: "lightSecondary.main",
                                  py: 1,
                                  px: 1.4,
                                  "&:hover": {
                                    backgroundColor: "extraLTheme.main",
                                  },
                                  "&.active": {
                                    backgroundColor: "primary.main",
                                    color: "#fff",
                                    borderRadius: "10px",
                                  },
                                }}
                              >
                                <VisibilityOutlinedIcon sx={{ mr: 1 }} />
                                Preview
                              </MenuItem> */}
                                  <MenuItem
                                    onClick={() => {
                                      handleOtherOptionsClose();
                                      handleShare();
                                    }}
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: "600",
                                      lineHeight: "20px",
                                      color: "lightSecondary.main",
                                      py: 1,
                                      px: 1.4,
                                      "&:hover": {
                                        backgroundColor: "extraLTheme.main",
                                      },
                                      "&.active": {
                                        backgroundColor: "primary.main",
                                        color: "#fff",
                                        borderRadius: "10px",
                                      },
                                    }}
                                  >
                                    <PersonAddAltOutlinedIcon sx={{ mr: 1 }} />
                                    Share
                                  </MenuItem>
                                  <MenuItem
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: "600",
                                      lineHeight: "20px",
                                      color: "lightSecondary.main",
                                      py: 1,
                                      px: 1.4,
                                      "&:hover": {
                                        backgroundColor: "extraLTheme.main",
                                      },
                                      "&.active": {
                                        backgroundColor: "primary.main",
                                        color: "#fff",
                                        borderRadius: "10px",
                                      },
                                    }}
                                    onClick={() => handleDownload()}
                                  >
                                    <FileDownloadOutlinedIcon sx={{ mr: 1 }} />
                                    Download
                                  </MenuItem>
                                  {/* <MenuItem
                                onClick={() => {
                                  handleMove();
                                  handleOtherOptionsClose();
                                }}
                                sx={{
                                  fontSize: "0.9rem",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                  color: "lightSecondary.main",
                                  py: 1,
                                  px: 1.4,
                                  "&:hover": {
                                    backgroundColor: "extraLTheme.main",
                                  },
                                  "&.active": {
                                    backgroundColor: "primary.main",
                                    color: "#fff",
                                    borderRadius: "10px",
                                  },
                                }}
                              >
                                <DriveFileMoveOutlinedIcon sx={{ mr: 1 }} />
                                Move to
                              </MenuItem> */}
                                  <MenuItem
                                    onClick={() => {
                                      handleRename();
                                      handleOtherOptionsClose();
                                    }}
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: "600",
                                      lineHeight: "20px",
                                      color: "lightSecondary.main",
                                      py: 1,
                                      px: 1.4,
                                      "&:hover": {
                                        backgroundColor: "extraLTheme.main",
                                      },
                                      "&.active": {
                                        backgroundColor: "primary.main",
                                        color: "#fff",
                                        borderRadius: "10px",
                                      },
                                    }}
                                  >
                                    <BorderColorOutlinedIcon sx={{ mr: 1 }} />
                                    Rename
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      handleOtherOptionsClose();
                                      deleteFolderAlert();
                                    }}
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: "600",
                                      lineHeight: "20px",
                                      color: "lightSecondary.main",
                                      py: 1,
                                      px: 1.4,
                                      "&:hover": {
                                        backgroundColor: "extraLTheme.main",
                                      },
                                      "&.active": {
                                        backgroundColor: "primary.main",
                                        color: "#fff",
                                        borderRadius: "10px",
                                      },
                                    }}
                                  >
                                    <DeleteOutlineOutlinedIcon sx={{ mr: 1 }} />
                                    Delete
                                  </MenuItem>
                                </Paper>
                              </Menu>
                            </div>
                          )}
                        </Box>
                      </ListItem>
                    );
                  })
              ) : (
                //   <Box
                //   sx={{
                //     display: "flex",
                //     justifyContent: "center", // Center horizontally
                //     alignItems: "center", // Center vertically
                //     height: "100%", // Take full available height
                //     width: "100%", // Take full available width
                //   }}
                // >
                //   <img
                //     src={getImages("noDataImage.gif")}
                //     alt="No Data Found"
                //     style={{
                //       maxWidth: "100%",
                //       maxHeight: "calc(100vh - 110px)",
                //       mixBlendMode: "multiply",
                //     }}
                //   />
                // </Box>
                <></>
              )}
            </List>
          </Box>
        </Box>
        {/* New Folder Modal */}
        <Modal open={isSubFolderClicked == true}>
          <div></div>
        </Modal>
        <Modals
          open={openNewFolder}
          modalWidth={480}
          handleClose={closeNewFolder}
        >
          <NewFolderModal
            handleClose={closeNewFolder}
            showToastMessage={showToastMessage}
            parentId={parentId}
          />
        </Modals>
        <Modals open={openRename} modalWidth={480} handleClose={closeRename}>
          <RenameModal
            handleClose={closeRename}
            showToastMessage={showToastMessage}
            documentId={documentId}
            documentName={documentName}
          />
        </Modals>
        <Modals open={openShare} handleClose={closeShare} modalWidth={480}>
          <ShareModal
            handleClose={closeShare}
            selectedBoardOpen={selectedBoardOpen}
          />
        </Modals>
        <Modals
          open={openSelectedBoard}
          handleClose={closeSelectedBoard}
          modalWidth={480}
          documentId={documentId}
          documentName={documentName}
        >
          <ShareSelectedBoardModal
            handleClose={closeSelectedBoard}
            shareChatOpen={shareChatOpen}
          />
        </Modals>
        <Modals
          open={openShareChat}
          handleClose={closeShareChat}
          modalWidth={480}
        >
          <ShareSolutionChatModal
            handleClose={closeShareChat}
            showToastMessage={showToastMessage}
          />
        </Modals>

        <Modals
          open={openDeleteFolderAlert}
          handleClose={goBackFromAlertHandle}
          modalWidth={500}
        >
          <DeleteFolderAlert
            handleClose={goBackFromAlertHandle}
            goBackFromAlertHandle={goBackFromAlertHandle}
            showToastMessage={showToastMessage}
            documentId={documentId}
            documentName={documentName}
          />
        </Modals>
        <Modals open={openMove} modalWidth={500} handleClose={closeMove}>
          <MoveModal
            handleClose={closeMove}
            showToastMessage={showToastMessage}
          />
        </Modals>
      </>
    );
  }
);

export default SubDriveFolder;
