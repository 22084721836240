import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';
export const getMessage = createApi({
    reducerPath: 'getMessage',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders:(header)=>{
            return header;
        }
    }),
    endpoints:(builder)=>({
        fetchGetMessage: builder.query({
        query:({groupChatId , userId})=>({
            url:`message/${groupChatId}?userId=${userId}`,
            method: 'GET',
        }),
        retry: (failureCount, error,) => {
            // Retry up to 3 times if the request fails due to network issues or server errors
            return failureCount <= 3 && (error.status === 503 || error.message.includes('Network Error'));
          },
        transformResponse: (response) => {
            return response;
        },
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch user details error:', error);
          // Handle error cases as per your application's requirements
        },
        }),

    })
})

export const{useFetchGetMessageQuery} =  getMessage;
export default  getMessage;