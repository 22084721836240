import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import {
  Avatar,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  Paper,
  TextField,
  Typography,
  styled,
  InputBase,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { useFetchInboxRequirementBoardDetailsQuery } from "../redux/api/Inbox/getInboxRequirementBoard";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useFetchInboxSelectedSolutionListQuery } from "../redux/api/Inbox/getInboxSelectedSolutionList";
import { useDispatch, useSelector } from "react-redux";
import { getSolutionList } from "../redux/features/inboxSolutions";
import { getBoardId, getSolutionId, getSolutionName } from "../redux/features/inboxSlice";
import { useFetchSolutionProviderRequirementBoardQuery } from "../redux/api/SolutionProvider/getBsiCoLabSolutionList";
const InboxSolutionsDropdown = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  
  const s3Url = process.env.REACT_APP_S3_BUCKET_URL;
  // get user id from localStorage
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDw = Boolean(anchorEl);
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  // const [selectedSolutionName, setSelectedSolutionName] = useState("View all solutions");
  const selectedSolutionName = useSelector((state)=> state.inbox.solutionName);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const queryParams = new URLSearchParams(location.search);
  const RedirectSolutionId = queryParams.get("solutionId");
  const RedirectBusinessUserId = queryParams.get("businessUserId");
  const RedirectSolutionName = queryParams.get("solutionName");

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.9, 0, 1.9, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));

  // solution listing for solution provider
  const {
    data: solutionsData,
    error: solutionsError,
    isLoading: solutionLoading,
    refetch: refetchSolutions,
  } = useFetchSolutionProviderRequirementBoardQuery({
    user_id: userId,
    sort: "asc",
    solution_name: searchValue,
  });

  // handleSearching logic
  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };
  const handleSearchSolutions = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue(""); // Reset the searchValue state
        await refetchSolutions();
        return;
      }
      setSearchValue(searchParams);

      if (solutionsError) {
        const errorMessage = solutionsData?.message || "noDataFound";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      await refetchSolutions();
    }
  };

  // Clear search after not found data
  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue("");
  };

  // storing selectedBoardId in localstorage
  const handleSolutionSelection = ( solutionId, solutionName) => {
      dispatch(getSolutionId( solutionId ));
      // setSelectedSolutionName(solutionName);
      dispatch(getSolutionName(  solutionName ));
   
  };

  useEffect(()=>{
    if(RedirectSolutionId && RedirectSolutionName){
      dispatch(getSolutionId( RedirectSolutionId ));
      // setSelectedSolutionName(solutionName);
      dispatch(getSolutionName( RedirectSolutionName  ));
    }
  },[])

  useEffect(() => {
    if (solutionsData?.solutions && solutionsData?.solutions.length === 1 ) {
      const singleSolution = solutionsData?.solutions[0];
      dispatch(getSolutionId( singleSolution._id ));
    }
  }, [solutionsData, dispatch]);


   
  return (
    <>
      <Box display={"flex"} sx={{ px: "0" }}>
        {/* {solutionsData &&
        solutionsData.solutions &&
        solutionsData.solutions.length > 0 ? ( */}
          <ListItemButton
            onClick={handleClick}
            sx={{
              [theme.breakpoints.down("md")]: {
                p: 0.5,
              },
            }}
          >
            {/* <ListItemAvatar>
              <Box
                component="img"
                src={s3Url + solutionsData.solutions[0].logo}
                alt={solutionsData.solutions[0].solution_name}
                sx={{
                  width: "50px",
                  height: "40px",
                  objectFit: "contain",
                  backgroundColor: "#fff",
                  mixBlendMode: "multiply",
                }}
              />
            </ListItemAvatar> */}
            {solutionsData?.solutions && solutionsData?.solutions.length === 1 ? (
            solutionsData?.solutions.map((solution, index) => (
              <ListItemText
              key={solution._id}
              sx={{
                width: "60%",
                marginTop: "5px",
              }}
            >
                <Typography
                noWrap
                sx={{
                  fontSize: "1.6rem",
                  fontWeight: "700",
                  color: "secondary.main",
                  marginLeft: "5px",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "1.2rem",
                  },
                }}
                variant="h6"
              >
                {solution.solution_name}{" "}
                </Typography>
                </ListItemText>
            ))
          ) : !solutionsData?.solutions  ? (
            // What to display when no boards are available
            <Typography
              noWrap
              sx={{
                fontSize: "1.6rem",
                fontWeight: "700",
                color: "secondary.main",
                marginLeft: "5px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "1.2rem",
                },
              }}
              variant="h6"
            >
              No Solution Available
            </Typography>
          ) : (
            // What to display when more than one board exists
            <ListItemText sx={{ width: "60%", marginTop: "5px" }}>
            <Typography
              noWrap
              sx={{
                fontSize: "1.6rem",
                fontWeight: "700",
                color: "secondary.main",
                marginLeft: "5px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "1.2rem",
                },
              }}
              variant="h6"
            >
              {selectedSolutionName}
            </Typography>
          </ListItemText>
          )}
            <KeyboardArrowDownOutlinedIcon
              sx={{
                marginTop: "4px",
                fontSize: "36px",
                cursor: "pointer",
                [theme.breakpoints.down("md")]: {
                  fontSize: "26px",
                },
              }}
            />
          </ListItemButton>
        {/* ) 
        : (
          <ListItemText sx={{ width: "60%", marginTop: "5px" }}>
            <Typography
              noWrap
              sx={{
                fontSize: "1.6rem",
                fontWeight: "700",
                color: "secondary.main",
                marginLeft: "5px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "1.2rem",
                },
              }}
              variant="h6"
            >
              No Solution Available
            </Typography>
          </ListItemText>
        )} */}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openDw}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiMenu-paper": {
              borderRadius: "10px",
              border: "solid 1px #106EED",
              minWidth: "340px",
              p: 0,
            },
          }}
        >
          <Paper
            sx={{
              width: 420,
              maxWidth: "100%",
              background: "#fff",
              borderRadius: "10px",
              borderBottom: "none",
              boxShadow: "none",
              p: 1,
            }}
          >
            {/* <TextField
              id="searchBox"
              name="searchBox"
              type="search"
              label="Search"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            /> */}
            <Search
              sx={{
                backgroundColor: "extraLTheme.main",
                mb: 2,
              }}
            >
              <SearchIconWrapper>
                {searchParams ? (
                  <IconButton
                    onClick={handleClearSearch}
                    sx={{ color: "lightSecondary.main", cursor: "pointer" }}
                  >
                    <CancelIcon />
                  </IconButton>
                ) : (
                  <SearchIcon sx={{ color: "lightSecondary.main" }} />
                )}
              </SearchIconWrapper>
              <StyledInputBase
                id="searchBox"
                placeholder="Search board"
                inputProps={{ "aria-label": "search" }}
                value={searchParams}
                onChange={handleInputChange}
                onKeyDown={handleSearchSolutions}
                autoFocus
              />
            </Search>
            <Box
              sx={{
                maxHeight: "300px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "6px",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#9C9C9C",
                  borderRadius: "4px",
                },
              }}
            >
              {solutionsData?.solutions?.length > 0 ? (
                solutionsData.solutions.map((solution) => (
                  <MenuItem
                    key={solution._id}
                    onClick={() => {
                      handleSolutionSelection(solution._id,solution.solution_name);
                      handleClose();
                    }}
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "600",
                      lineHeight: "30px",
                      color: "lightSecondary.main", // Fixed color typo
                      py: 1.3,
                      px: 1.4,
                      width: "100%",
                      borderRadius: "4px",
                      "&:hover": {
                        backgroundColor: "extraLTheme.main",
                      },
                      "&.active": {
                        backgroundColor: "primary.main",
                        color: "#fff",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        width: "20%",
                        pt: 0.5,
                      }}
                    >
                      <Box
                        component="img"
                        src={s3Url + solution.logo}
                        alt={solution.solution_name}
                        sx={{
                          width: "40px",
                          height: "20px",
                          objectFit: "contain",
                          // borderColor: "ltheme.main",
                          backgroundColor: "#fff",
                          mixBlendMode: "multiply",
                        }}
                      />
                    </ListItemAvatar>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#2A589C",
                        fontSize: "1.1rem",
                        fontWeight: "700",
                        width: "80%",
                        maxWidth: "80%",
                        whiteSpace: "nowrap", // Prevents text wrapping
                        overflow: "hidden", // Ensures overflow text is hidden
                        textOverflow: "ellipsis", // Adds ellipsis for overflowing text
                      }}
                    >
                      <Tooltip title={solution.solution_name}>
                        {solution.solution_name}
                      </Tooltip>
                    </Typography>
                  </MenuItem>
                ))
              ) : (
                <ListItemText
                  sx={{
                    width: "60%",
                    marginTop: "5px",
                  }}
                >
                  <Typography
                    noWrap
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "700",
                      color: "secondary.main",
                      display: "flex",
                      marginLeft: "5px",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "1.2rem",
                      },
                    }}
                    variant="h6"
                  >
                    No Solution Available
                  </Typography>
                </ListItemText>
              )}
            </Box>
          </Paper>
        </Menu>
      </Box>
    </>
  );
};

export default InboxSolutionsDropdown;
