import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  Stack,
  Avatar,
  FormControl,
  MenuItem,
  TextField,
  Select,
  styled,
  IconButton,
} from "@mui/material";
import MainCard from "../../commonComponents/MainCard";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { getImages } from "../../commonComponents/const";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Link, useNavigate } from "react-router-dom";
import Modals from "../../commonComponents/Modals";
import ChangePassword from "../../commonComponents/Profile/ChangePassword";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useFetchBusinessUserDetailsQuery } from "../../redux/api/Users/getuserProfile";
import { hideLoader, showLoader } from "../../redux/features/loader";
import UpdateBusinessProfile from "../../commonComponents/Profile/businessProfile";
import UpdateSolutionProviderProfile from "../../commonComponents/Profile/solutionProviderProfile";
import { toast } from "react-toastify";
import { useFetchCountryListQuery } from "../../redux/api/Users/getCountryList";
import { useFetchUserDetailsQuery } from "../../redux/api/Users/getUser";
import Flag from "react-world-flags";
import { useUpdateUserProfileMutation } from "../../redux/api/Users/updateUserProfile";
import SvgIcon from "@mui/material/SvgIcon";
import EditIcon from "@mui/icons-material/Edit";
import shortid from "shortid";
const Profile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  //   toast message
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const {
    data: userData,
    error,
    isLoading: userDataLoading,
    refetch: refetchUserData,
  } = useFetchUserDetailsQuery(userId);
  const [
    updateUserProfile,
    { isLoading: profileUpdateLoading, isError: profileError },
  ] = useUpdateUserProfileMutation();

  // Update state when userData changes
  useEffect(() => {
    if (userData) {
      setSrName(userData?.salutation || "");
      setFirstName(userData?.firstName || "");
      setLastName(userData?.lastName || "");
      setPhoneCode(userData?.phoneCode || "");
      setPhoneNumber(userData?.phoneNumber || "");
      setCountryName(userData?.countryId?.name || "");
      setCountryId(userData?.countryId || "");
      setEmail(userData?.email || "");
    }
  }, [userData]);

  // State values
  const [srName, setSrName] = useState(userData?.salutation || "");
  const [firstName, setFirstName] = useState(userData?.firstName || "");
  const [lastName, setLastName] = useState(userData?.lastName || "");
  const [phoneCode, setPhoneCode] = useState(userData?.phoneCode || "");
  const [phoneNumber, setPhoneNumber] = useState(userData?.phoneNumber || "");
  const [countryName, setCountryName] = useState(
    userData?.countryId?.name || ""
  );
  const [countryId, setCountryId] = useState(userData?.countryId || "");
  const [email, setEmail] = useState(userData?.email || "");
  const [avatar, setAvatar] = useState(null); // State to store the selected file
  const [selectedAvatar, setSelectedAvatar] = useState([]);
  const open = useSelector((state) => state.drawer.open);

  // Update handle function to capture the selected file
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files); // Get the selected file
    setAvatar(newFiles); // Update the state with the selected file
    setSelectedAvatar((prevFiles) => [
      ...prevFiles,
      ...newFiles.map((file) => ({
        id: shortid.generate(),
        filename: file.name,
        filetype: file.type,
        fileimage: URL.createObjectURL(file),
        datetime: file.lastModifiedDate.toLocaleString("en-IN"),
        filesize: filesizes(file.size),
      })),
    ]);
  };
  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  // Get the preview URL of the latest selected avatar, if any
  const previewImage =
    selectedAvatar.length > 0
      ? selectedAvatar[selectedAvatar.length - 1].fileimage
      : userData?.logo || getImages("default_user.jpg");
  // handleSrName
  const handleSrName = (event) => {
    setSrName(event.target.value);
  };

  // handleEmail
  const handleEmail = (event) => {
    const inputValue = event.target.value.trim();
    setEmail(inputValue);
  };

  // handleCountry
  const {
    data: countryData,
    isLoading: countryLoading,
    isError: countryError,
  } = useFetchCountryListQuery();

  const handleCountry = (event) => {
    const selectedCountry = event.target.value.trim();
    if (selectedCountry === "") {
      setCountryName(data?.country_id?.name || "");
      setCountryId(countryData?.country_id._id || "");
    } else {
      setCountryName(selectedCountry);
      const selectedCountryObject = countryData?.data.find(
        (country) => country.name === selectedCountry
      );
      if (selectedCountryObject) {
        setCountryId(selectedCountryObject._id);
      } else {
        setCountryId("");
      }
    }
  };

  //   handlePhoneNumber
  const handlePhoneNumber = (event) =>
    setPhoneNumber(event.target.value.trim());
  // handleCountryCode
  const handleCountryCode = (event) => setPhoneCode(event.target.value.trim());
  // handleFirstName
  const handleFirstName = (event) => setFirstName(event.target.value.trim());
  // handleLastName
  const handleLastName = (event) => setLastName(event.target.value.trim());

  //Change Password Modal
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const handleChangePwd = () => {
    setOpenChangePassword(true);
  };
  const closeChangePwd = () => {
    setOpenChangePassword(false);
  };

  const {
    data: userProfileData,
    isLoading: userProfileLoading,
    isError: userError,
    refetchProfileData,
  } = useFetchBusinessUserDetailsQuery(userId);
  const { data } = userProfileData || {};
  const user = data?.user_id || {};
  const userType = data?.user_type || null;
  useEffect(() => {
    localStorage.setItem("userProfileUserType", userType);
  });

  const updateProfile = async () => {
    // Create a new FormData object
    const formData = new FormData();

    // Check if the avatar is selected, if not, use the existing logo from userData
    if (avatar && avatar.length > 0) {
      avatar.forEach((file) => formData.append("avatar", file)); // Append selected file(s) if any
    }

    // Append other user details to FormData
    formData.append("user_id", userId);
    formData.append(
      "salutation",
      srName !== userData.salutation ? srName : userData.salutation
    );
    formData.append(
      "first_name",
      firstName !== userData.firstName ? firstName : userData.firstName
    );
    formData.append(
      "last_name",
      lastName !== userData.lastName ? lastName : userData.lastName
    );
    formData.append("phone_code", phoneCode);
    formData.append("phone_number", phoneNumber);
    formData.append(
      "country_id",
      countryId !== userData.countryId ? countryId : userData.countryId
    );
    formData.append("email", email);

    try {
      const response = await updateUserProfile({
        userId: userId,
        formData,
      }).unwrap();
      refetchUserData(); // Refetch user data after successful update
      const successMessage = response?.message || "Profile updated";
      showToastMessage(successMessage, "success");
    } catch (error) {
      const errorMessage = error?.message || "Failed to update";
      showToastMessage(errorMessage, "error");
    }
  };

  // Edit and update state
  const [isReadable, setIsReadable] = useState(true);
  const handleEditClick = () => {
    if (!isReadable) {
      updateProfile();
      refetchUserData();
    }
    setIsReadable((prev) => !prev);
  };

  useEffect(() => {
    if (userDataLoading || profileUpdateLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [userDataLoading, profileUpdateLoading, dispatch]);

  return (
    <>
      <MainCard
        content={false}
        sx={{
          backgroundColor: "#ECF6FF",
          border: "none",
          padding: "15px",
          mb: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            p: 3,
            mb: 2,
          }}
        >
          <Grid
            container
            rowSpacing={0.1}
            columnSpacing={0}
            sx={{
              // mb: 2,
              alignItems: "flex-end",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                pr: 5,
                // borderRight: "solid 1px #A2C2F1",
                [theme.breakpoints.down("xl")]: {
                  pr: 3,
                },
                [theme.breakpoints.down("lg")]: {
                  pr: 0,
                  borderRight: "none",
                },
              }}
            >
              <Stack
                spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: "inherit",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mb: 2,
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "600",
                    color: "secondary.main",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                  }}
                >
                  User Information
                </Typography>

                {/* Edit Button  */}
                <Button
                  variant="text"
                  style={{ marginTop: "0" }}
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    textTransform: "inherit",
                  }}
                  onClick={handleEditClick}
                >
                  <BorderColorIcon
                    sx={{
                      marginLeft: "5px",
                      color: "primary.main",
                      width: "12px",
                      height: "12px",
                      mr: 1,
                    }}
                  />
                  {isReadable ? "Edit" : "Save"}
                </Button>
              </Stack>
              <Grid
                container
                rowSpacing={0.1}
                columnSpacing={2}
                sx={{
                  mb: 2,
                }}
              >
                {/* Profile Image */}
                <Grid item xs={2} sm={3} md={3} lg={2} xl={2}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      [theme.breakpoints.down("md")]: {
                        margin: "auto",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        "& .MuiButtonBase-root": {
                          mb: 0,
                        },
                      }}
                    >
                      <Avatar
                        src={previewImage}
                        alt={`${userData?.firstName} logo`}
                        slotProps={{
                          img: {
                            sx: {
                              maxWidth: "100%",
                              maxHeight: "100%",
                            },
                          },
                        }}
                        sx={{ width: 150, height: 150 }}
                      />
                      <IconButton
                        component="label"
                        disabled={isReadable}
                        sx={{
                          boxShadow: "0 0 5px rgb(0 0 0 / 20%)",
                          p: "7px",
                          position: "absolute",
                          right: "0px",
                          bottom: "0px",
                          margin: "0px",
                          backgroundColor: "common.white",
                          "&:hover": {
                            backgroundColor: "common.white",
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "common.white",
                            boxShadow: "0 0 5px rgb(0 0 0 / 20%)",
                            cursor: "not-allowed",
                          },
                        }}
                        tabIndex={-1}
                        size="small"
                      >
                        <SvgIcon
                          sx={{
                            width: "16px",
                            height: "16px",
                            color: "common.black",
                          }}
                          viewBox="0 0 32 32"
                          component={EditIcon}
                        />
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileChange}
                        />
                      </IconButton>
                      {/* </Avatar> */}
                    </Box>
                  </Box>
                </Grid>
                {/* Profile Info */}
                <Grid
                  item
                  xs={12}
                  md={9}
                  lg={10}
                  xl={10}
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      mt: 2,
                    },
                  }}
                >
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={{ xs: 2, sm: 2, xl: 2 }}
                      sx={{
                        mb: 2,
                        g: 2,
                        pl: 3,
                        "@media (max-width:1600px) and (min-width:1200px)": {
                          flexBasis: "100%",
                          maxWidth: "100%",
                        },
                        [theme.breakpoints.down("lg")]: {
                          pl: 0,
                        },
                      }}
                    >
                      <Grid item xs={12} md={6} lg={6} xl={6} sx={{ mb: 1 }}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "ltheme.main",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          FirstName
                        </Typography>
                        <Grid
                          container
                          rowSpacing={0}
                          columnSpacing={1}
                          sx={{
                            mb: 0,
                          }}
                        >
                          <Grid item xs={4} md={4} lg={5} xl={5}>
                            <FormControl
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                              }}
                            >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={srName}
                                onChange={handleSrName}
                                displayEmpty
                                disabled={isReadable}
                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                sx={{
                                  background: "#ECF6FF",
                                  "& .MuiSelect-select": {
                                    minHeight: "20px",
                                    padding: "11px 14px",
                                    color: "lightSecondary.main",
                                    fontWeight: "600",
                                    fontSize: "0.9rem",
                                    border: "solid 1px #DBEBF8",
                                  },
                                  "& .MuiSelect-icon": {
                                    color: "lightSecondary.main",
                                  },
                                }}
                              >
                                <MenuItem value={srName}>
                                  {userData?.salutation}
                                </MenuItem>
                                <MenuItem value="Mr">Mr</MenuItem>
                                <MenuItem value="Mrs">Mrs</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={8} md={8} lg={7} xl={7}>
                            <TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              value={firstName}
                              onChange={handleFirstName}
                              style={{
                                margin: 0,
                                width: "100%",
                              }}
                              InputProps={{ readOnly: isReadable }}
                              sx={{
                                width: "100%",
                                borderRadius: "4px",
                                backgroundColor: "extraLTheme.main",
                                border: "solid 1px #DBEBF8",
                                m: 0,
                                "& .MuiInputBase-root": {
                                  backgroundColor: "extraLTheme.main",
                                },
                                "& .MuiInputBase-input": {
                                  fontSize: "0.9rem",
                                  color: "lightSecondary.main",
                                  fontWeight: "500",
                                  pt: 1.5,
                                  pb: 1.5,
                                },
                                "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                                  {
                                    border: "none",
                                  },
                                "& .MuiInputBase-root:hover:before": {
                                  border: "none",
                                },
                                "&:hover": {
                                  borderColor: "#DBEBF8",
                                },
                                "& .MuiInputBase-root:before": {
                                  border: "none",
                                },
                                "& .MuiInputBase-root:after": {
                                  border: "none",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6} sx={{ mb: 1 }}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "ltheme.main",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Last Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          variant="filled"
                          value={lastName}
                          onChange={handleLastName}
                          style={{
                            margin: 0,
                            width: "100%",
                          }}
                          InputProps={{ readOnly: isReadable }}
                          sx={{
                            width: "100%",
                            borderRadius: "4px",
                            backgroundColor: "extraLTheme.main",
                            border: "solid 1px #DBEBF8",
                            m: 0,
                            "& .MuiInputBase-root": {
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "0.9rem",
                              color: "lightSecondary.main",
                              fontWeight: "500",
                              pt: 1.5,
                              pb: 1.5,
                            },
                            "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                              {
                                border: "none",
                              },
                            "& .MuiInputBase-root:hover:before": {
                              border: "none",
                            },
                            "&:hover": {
                              borderColor: "#DBEBF8",
                            },
                            "& .MuiInputBase-root:before": {
                              border: "none",
                            },
                            "& .MuiInputBase-root:after": {
                              border: "none",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6} sx={{ mb: 1 }}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "ltheme.main",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Country
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={countryName}
                            onChange={handleCountry}
                            displayEmpty
                            disabled={isReadable}
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            sx={{
                              background: "#ECF6FF",
                              "& .MuiSelect-select": {
                                minHeight: "20px",
                                padding: "11px 14px",
                                color: "lightSecondary.main",
                                fontWeight: "600",
                                fontSize: "0.9rem",
                                border: "solid 1px #DBEBF8",
                              },
                              "& .MuiSelect-icon": {
                                color: "lightSecondary.main",
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                  "&::-webkit-scrollbar": {
                                    width: "6px",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    backgroundColor: "transparent",
                                    borderRadius: "4px",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#9C9C9C",
                                    borderRadius: "4px",
                                  },
                                },
                              },
                            }}
                          >
                            <MenuItem value={countryName}>
                              {userData?.countryName || "Select Country*"}
                            </MenuItem>
                            {countryData &&
                              countryData.data.map((country) => (
                                <MenuItem
                                  key={country._id}
                                  value={country.name}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Flag
                                      code={country.iso2}
                                      style={{
                                        width: "20px",
                                        height: "15px",
                                        marginRight: "10px",
                                      }}
                                    />
                                    {country.name}
                                  </Box>
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        {/* <TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          variant="filled"
                          value={countryName}
                          onChange={handleCountry}
                          style={{
                            margin: 0,
                            width: "100%",
                          }}
                          InputProps={{ readOnly: isReadable }}
                          sx={{
                            width: "100%",
                            borderRadius: "4px",
                            backgroundColor: "extraLTheme.main",
                            border: "solid 1px #DBEBF8",
                            m: 0,
                            "& .MuiInputBase-root": {
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "0.9rem",
                              color: "lightSecondary.main",
                              fontWeight: "500",
                              pt: 1.5,
                              pb: 1.5,
                            },
                            "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                              {
                                border: "none",
                              },
                            "& .MuiInputBase-root:hover:before": {
                              border: "none",
                            },
                            "&:hover": {
                              borderColor: "#DBEBF8",
                            },
                            "& .MuiInputBase-root:before": {
                              border: "none",
                            },
                            "& .MuiInputBase-root:after": {
                              border: "none",
                            },
                          }}
                        /> */}
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6} sx={{ mb: 1 }}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "ltheme.main",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Contact Number
                        </Typography>
                        <Grid
                          container
                          rowSpacing={0}
                          columnSpacing={1}
                          sx={{
                            mb: 0,
                          }}
                        >
                          <Grid item xs={4} md={4} lg={5} xl={4}>
                            <FormControl
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                              }}
                            >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={phoneCode}
                                onChange={handleCountryCode}
                                displayEmpty
                                disabled={isReadable === false}
                                IconComponent={KeyboardArrowDownOutlinedIcon}
                                sx={{
                                  background: "#ECF6FF",
                                  "& .MuiSelect-select": {
                                    minHeight: "20px",
                                    padding: "11px 14px",
                                    color: "lightSecondary.main",
                                    fontWeight: "600",
                                    fontSize: "0.9rem",
                                    border: "solid 1px #DBEBF8",
                                  },
                                  "& .MuiSelect-icon": {
                                    color: "lightSecondary.main",
                                  },
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      "&::-webkit-scrollbar": {
                                        width: "6px",
                                        borderRadius: "4px",
                                      },
                                      "&::-webkit-scrollbar-track": {
                                        backgroundColor: "transparent",
                                        borderRadius: "4px",
                                      },
                                      "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: "#9C9C9C",
                                        borderRadius: "4px",
                                      },
                                    },
                                  },
                                  color: isReadable
                                    ? "lightSecondary.main"
                                    : "lightSecondary.main",
                                }}
                              >
                                <MenuItem value={phoneCode} disabled>
                                  {userData?.phoneCode || "ph-code*"}{" "}
                                </MenuItem>
                                {countryData &&
                                  countryData.data.map((country) => (
                                    <MenuItem
                                      key={country._id}
                                      value={country.phone_code}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Flag
                                          code={country.iso2}
                                          style={{
                                            width: "20px",
                                            height: "15px",
                                            marginRight: "10px",
                                          }}
                                        />
                                        {country.phone_code}
                                      </Box>
                                    </MenuItem>
                                  ))}
                                {/* <MenuItem value={0}>{user.phoneCode}</MenuItem> */}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={8} md={8} lg={7} xl={8}>
                            <TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              variant="filled"
                              value={phoneNumber}
                              onChange={handlePhoneNumber}
                              style={{
                                margin: 0,
                                width: "100%",
                              }}
                              InputProps={{ readOnly: true }}
                              sx={{
                                width: "100%",
                                borderRadius: "4px",
                                backgroundColor: "extraLTheme.main",
                                border: "solid 1px #DBEBF8",
                                m: 0,
                                "& .MuiInputBase-root": {
                                  backgroundColor: "extraLTheme.main",
                                },
                                "& .MuiInputBase-input": {
                                  fontSize: "0.9rem",
                                  color: "lightSecondary.main",
                                  fontWeight: "500",
                                  pt: 1.5,
                                  pb: 1.5,
                                },
                                "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                                  {
                                    border: "none",
                                  },
                                "& .MuiInputBase-root:hover:before": {
                                  border: "none",
                                },
                                "&:hover": {
                                  borderColor: "#DBEBF8",
                                },
                                "& .MuiInputBase-root:before": {
                                  border: "none",
                                },
                                "& .MuiInputBase-root:after": {
                                  border: "none",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "ltheme.main",
                            width: "100%",
                            mb: 0.5,
                          }}
                        >
                          Email Address
                        </Typography>
                        <TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          variant="filled"
                          value={email}
                          onChange={handleEmail}
                          style={{
                            margin: 0,
                            width: "100%",
                          }}
                          InputProps={{ readOnly: true }}
                          sx={{
                            width: "100%",
                            borderRadius: "4px",
                            backgroundColor: "extraLTheme.main",
                            border: "solid 1px #DBEBF8",
                            m: 0,
                            "& .MuiInputBase-root": {
                              backgroundColor: "extraLTheme.main",
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "0.9rem",
                              color: "lightSecondary.main",
                              fontWeight: "500",
                              pt: 1.5,
                              pb: 1.5,
                            },
                            "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before":
                              {
                                border: "none",
                              },
                            "& .MuiInputBase-root:hover:before": {
                              border: "none",
                            },
                            "&:hover": {
                              borderColor: "#DBEBF8",
                            },
                            "& .MuiInputBase-root:before": {
                              border: "none",
                            },
                            "& .MuiInputBase-root:after": {
                              border: "none",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* change password and logout button */}
            {/* <Grid
              item
              xs={12}
              md={12}
              lg={3}
              sx={{
                pl: 5,
                [theme.breakpoints.down("lg")]: {
                  pl: 0,
                },
              }}
            >
              <Stack
                gap={2}
                sx={{
                  width: "100%",
                  mb: 2,
                  [theme.breakpoints.down("sm")]: {
                    display: "block",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    [theme.breakpoints.down("sm")]: {
                      width: "100%",
                    },
                  }}
                >
                  <Button
                    onClick={handleChangePwd}
                    variant="outlined"
                    sx={{
                      textTransform: "inherit",
                      p: "8px 15px",
                      fontSize: "1rem",
                      borderWidth: "2px",
                      fontWeight: "600",
                      borderColor: "#106EED",
                      width: "100%",
                      "&:hover": {
                        borderWidth: "2px",
                      },
                    }}
                  >
                    Change Password
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    [theme.breakpoints.down("sm")]: {
                      width: "100%",
                      mt: 1.5,
                    },
                  }}
                >
                  <Link to={"https://bsda81.wedighq.com/logout"}>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "inherit",
                        p: "8px 15px",
                        fontSize: "1rem",
                        borderWidth: "2px",
                        fontWeight: "600",
                        borderColor: "red.main",
                        color: "red.main",
                        width: "100%",
                        "&:hover": {
                          borderWidth: "2px",
                          borderColor: "red.main",
                          color: "red.main",
                        },
                      }}
                      onClick={handleLogOut}
                    >
                      Log Out
                      <LogoutIcon
                        sx={{
                          fontSize: "1.2rem",
                          ml: 4,
                          [theme.breakpoints.down("xl")]: {
                            ml: 2,
                          },
                        }}
                      />
                    </Button>
                  </Link>
                </Box>
              </Stack>
            </Grid> */}
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            alignItems: "flex-start",
            justifyContent: "space-between",
            p: 3,
            mb: 2,
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "flex-start",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              // flexDirection: "inherit",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {userType === "solution_provider" ? (
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  color: "secondary.main",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "1rem",
                  },
                  // alignSelf: "flex-start",
                }}
              >
                Business Profile
              </Typography>
            ) : (
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  color: "secondary.main",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "1rem",
                  },
                  // alignSelf: "flex-start",
                }}
              >
                Business Profile
              </Typography>
            )}

            {/* <Button
              variant="text"
              style={{ marginTop: "0" }}
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                textTransform: "inherit",
                // margin:'auto'
                // alignSelf: "flex-end",
              }}
              onClick={handleEditClick}
            >
              <BorderColorIcon
                sx={{
                  marginLeft: "5px",
                  color: "primary.main",
                  width: "12px",
                  height: "12px",
                  mr: 1,
                }}
              />
              {isReadable ? "Edit" : "Save"}
            </Button> */}
          </Box>
          <Box
            sx={{
              //   textAlign: "center",
              //   pt: 20,
              //   pb: 20,
              [theme.breakpoints.down("md")]: {
                // pt: 10,
                // pb: 10,
              },
            }}
          >
            {/* <Typography
              variant="body2"
              sx={{
                fontSize: "1.1rem",
                fontWeight: "500",
                color: "ltheme.main",
                width: "100%",
                mb: 2,
                [theme.breakpoints.down("md")]: {
                  fontSize: "1rem",
                },
              }}
            >
              Business Profile available, Click below to view and update your
              business profile.
            </Typography>
            <Link to="/profile-business">
              <Button
                variant="contained"
                sx={{
                  textTransform: "inherit",
                  p: "12px 45px",
                  boxShadow: "none",
                  fontWeight: "600",
                }}
              >
                Update Profile
              </Button>
            </Link> */}
            {userType === "solution_provider" ? (
              <UpdateSolutionProviderProfile
                showToastMessage={showToastMessage}
              />
            ) : (
              <UpdateBusinessProfile showToastMessage={showToastMessage} />
            )}
          </Box>
        </Box>
      </MainCard>
      <Modals open={openChangePassword} modalWidth={600}>
        {/* <NewFolderModal handleClose={closeNewFolder}/> */}
        <ChangePassword handleClose={closeChangePwd} />
      </Modals>
    </>
  );
};

export default Profile;
