import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  FormGroup,
  Tooltip,
  Chip,
  Avatar,
  Badge,
  styled,
  ListItemAvatar,
  IconButton,
  Stack,
  CardContent,
  Card,
  ListItemText,
  Grid,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTheme } from "@emotion/react";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFetchViewRequirementQuery } from "../../redux/api/Requirement Boards/viewRequirement";
import Modals from "../../commonComponents/Modals";
import NorthOutlinedIcon from "@mui/icons-material/NorthOutlined";
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import DeleteBoardAlert from "../../commonComponents/Kanban/DeleteBoardAlert";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { getImages } from "../../commonComponents/const";
import { useFetchBusinessUserRequirementQuery } from "../../redux/api/SolutionProvider/getBusinessUserRequirement";
import NoDataScreen from "../../commonComponents/noData";
import { useUpdateSolutionStatusMutation } from "../../redux/api/Requirement Boards/updateSolutionStatus";
import { setRefetchSolutionProviderKanbanBoard } from "../../redux/features/refetch";
// import MemoryIcon from '../../assets/img/tech2-icon.png';
// import HealthAndSafetyIcon from '../../assets/img/industries-icon.svg';
// import FingerprintIcon from '../../assets/img/use-cases-icon.svg';

const BusinessUserRequirement = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const userType = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  // Extract boardName from URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const boardName = queryParams.get("boardName");
  const boardId = queryParams.get("boardId");
  const solutionId = queryParams.get("solutionId");
  const solutionUserId = queryParams.get("userId");
  // Function to get isAccepted state from localStorage
  const getInitialIsAccepted = () => {
    const savedState = localStorage.getItem("isAccepted");
    return savedState ? JSON.parse(savedState) : false;
  };

  const [isAccepted, setIsAccepted] = useState(getInitialIsAccepted);

  const handleGoBack = () => {
    navigate("/bsi-co-lab/kanban");
    localStorage.removeItem("isAccepted");
  };
  // toast messages
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // Delete list1Alert Modal
  const [openDeleteBoardAlert, setOpenDeleteBoardAlert] = useState(false);
  const deleteBoardAlertHandle = () => {
    setOpenDeleteBoardAlert(true);
  };
  const closeDeleteBoardAlert = () => {
    setOpenDeleteBoardAlert(false);
  };
  const goBackFromAlertHandle = () => {
    setOpenDeleteBoardAlert(false);
  };
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  const {
    data: requirementData,
    error: requirementError,
    isLoading: requirementLoading,
    refetch: refetchRequirement,
  } = useFetchBusinessUserRequirementQuery({
    userId: solutionUserId,
    boardId: boardId,
  });
  const [updateSolutionStatus, { isLoading: statusLoading }] =
    useUpdateSolutionStatusMutation();
  const AcceptInvitation = async () => {
    try {
      const response = await updateSolutionStatus({
        id: solutionId,
        status: "Responded",
        userId: solutionUserId,
      }).unwrap();
      const successMessage = response?.message || "Invitation accepted";
      showToastMessage(successMessage, "success");
      dispatch(setRefetchSolutionProviderKanbanBoard());

      setIsAccepted(true);
      localStorage.setItem("isAccepted", JSON.stringify(true)); // Persist the state in localStorage
    } catch (error) {
      const errorMessage = error?.message || "Failed to accept invitation";
      showToastMessage(errorMessage, "error");
    }
  };

  const RejectInvitation = async () => {
    try {
      const response = await updateSolutionStatus({
        id: solutionId,
        status: "Rejected",
        userId: solutionUserId,
      }).unwrap();
      const successMessage = response?.message || "Invitation rejected";
      showToastMessage(successMessage, "success");
      dispatch(setRefetchSolutionProviderKanbanBoard());

      // Remove from localStorage when rejected
      localStorage.removeItem("isAccepted");
      setIsAccepted(false);
    } catch (error) {
      const errorMessage = error?.message || "Failed to reject invitation";
      showToastMessage(errorMessage, "error");
    }
  };

  useEffect(() => {
    if (requirementLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [requirementLoading, dispatch]);

  return (
    <>
      {requirementData ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="12px"
          >
            {/* Board Name Field */}
            <Typography
              sx={{
                fontSize: "1.4rem",
                fontWeight: "700",
                color: "secondary.main",
                [theme.breakpoints.down("md")]: {
                  fontSize: "1.1rem",
                  maxWidth: "350px",
                },
                [theme.breakpoints.down("sm")]: {
                  maxWidth: "250px",
                },
              }}
              variant="h4"
              noWrap
              component="div"
            >
              <Link
                style={{
                  color: "#2A589C",
                  textDecoration: "none",
                }}
              >
                <KeyboardBackspaceIcon
                  style={{
                    verticalAlign: "-8px",
                    fontSize: "32px",
                    marginRight: "7px",
                  }}
                  onClick={handleGoBack}
                />
              </Link>
              <Tooltip title={boardName}>{boardName}</Tooltip>
            </Typography>
          </Box>
          {/* first box */}
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={
              {
                // mb: 2,
              }
            }
          >
            <Card
              sx={{
                boxShadow: "none",
                borderRadius: "10px",
              }}
            >
              <CardContent
                sx={{
                  p: 0,
                  [theme.breakpoints.down("sm")]: {
                    position: "relative",
                  },
                }}
                style={{
                  paddingBottom: "0",
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 2,
                    [theme.breakpoints.down("md")]: {
                      py: 3,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between", // Distribute space between the text and the icon
                      alignItems: "center", // Align items vertically in the center
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        lineHeight: "normal",
                        fontSize: "1.4rem",
                        fontWeight: "700",
                        color: "#073985",
                      }}
                    >
                      Business Profile
                    </Typography>

                    <Link
                      to={requirementData?.businessUserDetail?.business_url}
                      target="_blank"
                      sx={{ textDecoration: "none" }}
                    >
                      <IconButton
                        sx={{
                          p: 0.5,
                          pb: 2,
                          color: "lightSecondary.main", // Apply color directly to the IconButton
                        }}
                      >
                        <OpenInNewOutlinedIcon />
                      </IconButton>
                    </Link>
                  </Box>

                  <Stack
                    direction="row"
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: "74px",
                      [theme.breakpoints.down("sm")]: {
                        display: "block",
                      },
                    }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        alignItems: "center",
                        [theme.breakpoints.down("sm")]: {
                          display: "block",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          // minWidth: "100px",
                          pr: 2,
                          "& img": {
                            [theme.breakpoints.down("sm")]: {
                              width: "auto !important",
                              // maxWidth: "100%",
                              maxHeight: "50px",
                            },
                          },
                        }}
                      >
                         {requirementData?.businessUserDetail?.avatar ? (
                          // If avatar exists, show the image
                          <img
                            style={{ width: "100%", mixBlendMode: "multiply" }}
                            src={requirementData?.businessUserDetail?.avatar}
                          />
                        ) : (
                          // If avatar is missing, show MUI Avatar with business name initials
                          <Avatar
                            sx={{
                              bgcolor: 'lightSecondary.main',
                              width: 50,
                              height: 50,
                              fontSize: "1.5rem",
                              color:'#fff'
                            }}
                          >
                            {requirementData?.businessUserDetail?.business_name
                            ?.substring(0, 2) 
                            .toUpperCase()     
                          }

                          </Avatar>
                        )}
                      </Box>
                      <Box
                        sx={{
                          minWidth: "100px",
                          // pr: 2,
                          pb: 1,
                        }}
                      >
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          sx={{
                            fontSize: "1.1rem",
                            fontWeight: "700",
                            color: "secondary.main",
                            mb: 0,
                          }}
                        >
                          {requirementData?.businessUserDetail?.business_name}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    pt: 2,
                    px: 2,
                    backgroundColor: "rgba(219, 235, 248, 0.2)",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    borderTop: "solid 1px rgba(162, 194, 241, 1)",
                    minHeight: "70px",
                    [theme.breakpoints.down("md")]: {
                      flexWrap: "wrap",
                      px: 2,
                    },
                  }}
                >
                  <Box
                    display={"flex"}
                    sx={{
                      width: "33.33%",
                      pr: 1,
                      [theme.breakpoints.down("md")]: {
                        width: "50%",
                      },
                    }}
                  >
                    <Box>
                      <img
                        style={{ display: "block", marginTop: "7px" }}
                        src={getImages("headquarter_icon.png")}
                      />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: "10px",
                        width: "60%",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: "700",
                          color: "secondary.main",
                          lineHeight: "15px",
                        }}
                        variant="h6"
                      >
                        Headquarters
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "lightSecondary.main",
                        }}
                      >
                        {requirementData?.businessUserDetail?.country_id?.name}
                      </Typography>
                    </ListItemText>
                  </Box>
                  <Box
                    display={"flex"}
                    sx={{
                      width: "33.33%",
                      pr: 1,
                      [theme.breakpoints.down("md")]: {
                        width: "50%",
                      },
                    }}
                  >
                    <Box style={{ display: "block", marginTop: "7px" }}>
                      <PersonOutlineIcon sx={{ color: "#007AFF" }} />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: "10px",
                        width: "60%",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: "700",
                          color: "secondary.main",
                          lineHeight: "15px",
                        }}
                        variant="h6"
                      >
                        Company Role
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "lightSecondary.main",
                        }}
                      >
                        {requirementData?.businessUserDetail?.company_role}
                      </Typography>
                    </ListItemText>
                  </Box>
                  <Box
                    display={"flex"}
                    sx={{
                      width: "33.33%",
                      pr: 1,
                      [theme.breakpoints.down("md")]: {
                        width: "50%",
                      },
                    }}
                  >
                    <Box style={{ display: "block", marginTop: "7px" }}>
                      <AttachMoneyIcon sx={{ color: "#007AFF" }} />
                    </Box>
                    <ListItemText
                      sx={{
                        pl: "10px",
                        width: "60%",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          fontSize: "12px",
                          fontWeight: "700",
                          color: "secondary.main",
                          lineHeight: "15px",
                        }}
                        variant="h6"
                      >
                        Contact Name
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "lightSecondary.main",
                        }}
                      >
                        {requirementData?.businessUserDetail?.user_id &&
                          `${requirementData.businessUserDetail.user_id.salutation} ${requirementData.businessUserDetail.user_id.first_name} ${requirementData.businessUserDetail.user_id.last_name}`.trim()}
                      </Typography>
                    </ListItemText>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* Accept and Reject Buttons */}
          <Box display="flex" gap="12px" width="100%" justifyContent="flex-end">
            <Button
              sx={{
                backgroundColor: "blue",
                color: "white",
                minWidth: "15%",
                maxWidth: "50%",
                "&:hover": {
                  backgroundColor: "blue",
                  color: "white",
                },
                "&.Mui-disabled": {
                  backgroundColor: "blue", 
                  color: "white", 
                  opacity: 1, 
                  boxShadow: "none", 
                },
              }}
              onClick={AcceptInvitation}
              disabled={isAccepted} // Disable button if accepted
            >
              {isAccepted ? "Accepted" : "Accept"}
            </Button>

            {/* Hide Reject Button if accepted */}
            {!isAccepted && (
              <Button
                sx={{
                  backgroundColor: "red.main",
                  color: "white",
                  minWidth: "15%",
                  maxWidth: "50%",
                  "&:hover": {
                    backgroundColor: "red.main",
                    color: "white",
                  },
                }}
                onClick={RejectInvitation}
              >
                Not Interested
              </Button>
            )}
          </Box>
          {/* second box */}
          <Box
            sx={{
              // maxWidth: "900px",
              margin: "auto",
              width: "100%",
              padding: "2rem",
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  lineHeight: "normal",
                  fontSize: "1.4rem",
                  fontWeight: "700",
                  color: "#073985",
                }}
              >
                Business Requirement
              </Typography>
            </Box>
            {requirementData?.solutionRequirementData?.map(
              (requirement, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "2rem",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    minWidth="20%"
                    maxWidth="20%"
                    sx={{ justifyContent: "flex-start", flex: 1 }}
                  >
                    <img
                      src="https://www.blueskyintel.com/frontend/img/cpu.png"
                      alt="tech"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "10px",
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          lineHeight: "normal",
                          fontSize: "1rem",
                          fontWeight: "700",
                          color: "#073985",
                        }}
                      >
                        Tech
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          marginTop: "4px",
                          fontSize: "0.8rem",
                          color: "secondary.main",
                          fontWeight: "500",
                          lineHeight: "normal",
                        }}
                      >
                        {requirement.tech}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    minWidth="20%"
                    maxWidth="20%"
                    sx={{ justifyContent: "center", flex: 1 }}
                  >
                    <img
                      src="https://www.blueskyintel.com/frontend/img/industries.svg"
                      alt="health"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "10px",
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          lineHeight: "normal",
                          fontSize: "1rem",
                          fontWeight: "700",
                          color: "#073985",
                        }}
                      >
                        Industries
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          marginTop: "4px",
                          fontSize: "0.8rem",
                          color: "secondary.main",
                          fontWeight: "500",
                          lineHeight: "normal",
                        }}
                      >
                        {requirement.industry}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    minWidth="20%"
                    maxWidth="20%"
                    sx={{ justifyContent: "center", flex: 1 }}
                  >
                    <img
                      src="https://www.blueskyintel.com/frontend/img/use-cases.svg"
                      alt="tech"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "10px",
                      }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          lineHeight: "normal",
                          fontSize: "1rem",
                          fontWeight: "700",
                          color: "#073985",
                        }}
                      >
                        Use Cases
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          marginTop: "4px",
                          fontSize: "0.8rem",
                          color: "secondary.main",
                          fontWeight: "500",
                          lineHeight: "normal",
                        }}
                      >
                        {requirement.use_case}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )
            )}

            <Box>
              {requirementData?.solutionRequirementData?.[0]
                ?.user_question_responses?.length > 0 ? (
                requirementData.solutionRequirementData[0].user_question_responses.map(
                  (question, index) => {
                    const options = JSON.parse(question.options[0] || "[]");
                    const selectedOptions = JSON.parse(
                      question.selected_option[0] || "[]"
                    );

                    const filteredOptions = options.filter((option) =>
                      selectedOptions.includes(option)
                    );

                    return (
                      <Box
                        key={question._id}
                        sx={{ marginBottom: "1.5rem" }}
                        color="#143f86"
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontWeight: "700",
                            color: "#073985",
                          }}
                        >
                          {index + 1}. {question.question}
                        </Typography>

                        {question?.question_type === "multi-select" || question?.question_type === "multi" || question?.question_type === "multi-choice" ? (
                          <FormGroup
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                          }}
                        >
                          {filteredOptions.map((option) => (
                            <FormControlLabel
                              key={option}
                              control={
                                <Checkbox
                                  checked
                                  readOnly
                                  sx={{ color: "#073985" }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    color: "#073985",
                                    fontWeight: "500",
                                  }}
                                >
                                  {option}
                                </Typography>
                              }
                            />
                          ))}
                        </FormGroup>
                        ) : (
                          <RadioGroup>
                          {filteredOptions.map((option) => (
                            <FormControlLabel
                              key={option}
                              value={option}
                              control={
                                <Radio
                                  sx={{ color: "#073985" }}
                                  checked
                                  readOnly
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    color: "#073985",
                                    fontWeight: "500",
                                  }}
                                >
                                  {option}
                                </Typography>
                              }
                            />
                          ))}
                        </RadioGroup>
                        )}
                      </Box>
                    );
                  }
                )
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                    width: "100%",
                  }}
                >
                  <NoDataScreen />
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <Button
              sx={{
                backgroundColor: "blue",
                color: "white",
                minWidth: "15%",
                maxWidth: "50%",
                "&:hover": {
                  backgroundColor: "blue",
                  color: "white",
                },
                "&.Mui-disabled": {
                  backgroundColor: "blue", 
                  color: "white", 
                  opacity: 1, 
                  boxShadow: "none", 
                },
              }}
              onClick={AcceptInvitation}
              disabled={isAccepted} // Disable button if accepted
            >
              {isAccepted ? "Accepted" : "Accept"}
            </Button>

            {/* Hide Reject Button if accepted */}
            {!isAccepted && (
              <Button
                sx={{
                  backgroundColor: "red.main",
                  color: "white",
                  minWidth: "15%",
                  maxWidth: "50%",
                  "&:hover": {
                    backgroundColor: "red.main",
                    color: "white",
                  },
                }}
                onClick={RejectInvitation}
              >
                Not Interested
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}

      <Modals
        open={openDeleteBoardAlert}
        handleClose={closeDeleteBoardAlert}
        modalWidth={500}
      >
        <DeleteBoardAlert
          handleClose={closeDeleteBoardAlert}
          goBackFromAlertHandle={goBackFromAlertHandle}
          showToastMessage={showToastMessage}
          currentBoardId={boardId}
          deleteTitle={"requirement"}
        />
      </Modals>
    </>
  );
};

export default BusinessUserRequirement;
