import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  styled,
  InputBase,
  ListItemButton,
  IconButton,
  Badge,
  List,
  Button,
  MenuItem,
  Menu,
  Paper,
  Divider,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { getImages } from "../const";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
    getChatId,
    getGroupName,
    getSolutionLogo,
  setBusinessUserId,
  setOpenChat,
  setSolutionId,
  setSolutionName,
  setSolutionProviderId,
  toggleChatData,
  toggleGroupSelected,
} from "../../redux/features/chatSlice";
import { toggleDrawer } from "../../redux/features/drawerSlice";
import { useFetchInboxSelectedSolutionListQuery } from "../../redux/api/Inbox/getInboxSelectedSolutionList";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { toast } from "react-toastify";
import { UserAddOutlined } from "@ant-design/icons";
import InvitetoBSIColab from "./InvitetoBSIColab";
import Modals from "../Modals";
import { resetRefetchSolutionList, setRefetchTeamMemberList } from "../../redux/features/refetch";

const AllSolutionsList = ({ showToastMessage }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const logoBaseUrl = process.env.REACT_APP_S3_BUCKET_URL;
  const userId = localStorage.getItem("userId");
  const boardId = useSelector((state) => state.inbox.boardId);
  const {  solutionId } = useSelector((state) => state.chat);
  // Search Box
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#ECF6FF",
    "&:hover": {
      backgroundColor: "#ECF6FF",
    },
    marginRight: "0",
    marginLeft: 0,
    marginBottom: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#106EED",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.7, 0, 1.7, 1.7),
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: "12px",
      fontWeight: "500",
    },
  }));

  // searching Logic
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const {
    data: solutionList,
    error: boardDetailError,
    isLoading: boardDetailLoading,
    refetch,
  } = useFetchInboxSelectedSolutionListQuery({
    userId: userId,
    selectedBoardId: boardId,
    searchParams: searchValue,
  });
   
    
   // set refetchBusinessUserList in reduxStore
//    const refetchSolutionList = useSelector(
//     (state) => state.refetch.refetchSolutionList
//   );
//   useEffect(() => {
//     if (refetchSolutionList) {
//       refetch().finally(() => {
//         dispatch(resetRefetchSolutionList());
//       });
//     }
//   }, [refetchSolutionList, refetch, dispatch]);


  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };

  const handleSearchSolutionProvider = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue(""); 
        await refetch();
        return;
      }
      setSearchValue(searchParams);
      await refetch();
      if (boardDetailError) {
        const errorMessage = solutionList?.data?.message || "noDataFound";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue(""); // Reset search value to empty
    refetch(); // Refetch all data
  };

  // InviteBsiCoLab Modal
  const [openInviteBsiCoLab, setOpenInviteBsiCoLab] = useState(false);
  const InviteBsiCoLabHandle = () => {
    setOpenInviteBsiCoLab(true);
  };
  const closeInviteBsiCoLab = () => {
    setOpenInviteBsiCoLab(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDw = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const openStatus = Boolean(anchorElStatus);
  const handleStatusClick = (event) => {
    setAnchorElStatus(event.currentTarget);
  };
  const handleStatusClose = () => {
    setAnchorElStatus(null);
  };

  const [anchorElSolution, setAnchorElSolution] = React.useState(null);
  const openSolution = Boolean(anchorElSolution);
  const handleSolutionClick = (event) => {
    setAnchorElSolution(event.currentTarget);
  };
  const handleSolutionClose = () => {
    setAnchorElSolution(null);
  };
  const { open } = useSelector((state) => state.drawer);

  const { openChat, openSolutionList, chatData, solutionProviderId } =
    useSelector((state) => state.chat);
  const chatOpenHandle = (
    buttonIndex,
    solutionProviderId,
    solutionId,
    businessUserId,
    solutionName,
    solutionLogo,
    groupChatId
  ) => {
    //  dispatch(setOpenChat({
    //     ...openChat,
    //     [buttonIndex] : !openChat[buttonIndex]
    //  }));
    dispatch(setSolutionProviderId(solutionProviderId));
    dispatch(setSolutionId(solutionId));
    dispatch(setBusinessUserId(businessUserId));
    dispatch(setSolutionName(solutionName));
    dispatch(getGroupName(solutionName));
    dispatch(getSolutionLogo(solutionLogo));
    dispatch(getChatId(groupChatId));
    dispatch(toggleGroupSelected(true));
    if (open) {
      dispatch(toggleDrawer());
    }
  };

  const refetchTeamMemberList = () => {
    dispatch(setRefetchTeamMemberList(true));
  };

  useEffect(() => {
    if (boardDetailLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [boardDetailLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "secondary.main",
            fontSize: "0.9rem",
            fontWeight: "700",
            pr: 1.5,
          }}
        >
          Solution Chats
          {/* <div
          style={{
            position: "relative",
          }}
        >
          <Button
            id="basic-button1"
            aria-controls={openUser ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={openUser ? "true" : undefined}
            onClick={handleBusinessUserClick}
            sx={{
              p: "10px 0",
              minWidth: "10px",
              width: "100%",
              verticalAlign: "-5px",
              display: "flex",
              justifyContent: "space-between",
              color: "secondary.main",
            }}
          >
            <MoreVertOutlinedIcon
              sx={{
                fontSize: "1.2rem",
              }}
            />
          </Button>
          <Menu
            id="basic-menu1"
            anchorEl={anchorElSolution}
            open={openUser}
            onClose={handleBusinessUserClose}
            MenuListProps={{
              "aria-labelledby": "basic-button1",
            }}
            sx={{
              "& .MuiMenu-paper": {
                borderRadius: "4px",
                border: "none",
                minWidth: "210px",
                p: 0.5,
              },
            }}
          >
            <Paper
              sx={{
                width: 210,
                maxWidth: "100%",
                background: "#fff",
                borderRadius: "10px",
                borderBottom: "none",
                boxShadow: "none",
                p: 0.5,
              }}
            >
              <MenuItem
                onClick={handleBusinessUserClose}
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "lightSecondary.main",
                  py: 1,
                  px: 1.4,
                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                  "&.active": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              >
                All
              </MenuItem>
              <MenuItem
                onClick={handleBusinessUserClose}
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "lightSecondary.main",
                  py: 1,
                  px: 1.4,
                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                  "&.active": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              >
                Archived
              </MenuItem>
              <MenuItem
                onClick={handleBusinessUserClose}
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "lightSecondary.main",
                  py: 1,
                  px: 1.4,
                  "&:hover": {
                    backgroundColor: "extraLTheme.main",
                  },
                  "&.active": {
                    backgroundColor: "primary.main",
                    color: "#fff",
                    borderRadius: "10px",
                  },
                }}
              >
                Muted
              </MenuItem>
            </Paper>
          </Menu>
        </div> */}
        </Typography>
        <Button
          variant="none"
          sx={{
            textTransform: "inherit",
            // p: "10px 0 10px 10px",
            color: "primary.main",
            fontWeight: "600",
            "&:hover": {
              background: "transparent",
            },
            "@media (max-width:1600px) and (min-width: 1300px)": {
              fontSize: "0.9rem",
            },
            [theme.breakpoints.down("xl")]: {
              fontSize: "0.8rem",
            },
          }}
          onClick={InviteBsiCoLabHandle}
        >
          <UserAddOutlined
            style={{
              width: "15px",
              height: "20px",
              position: "relative",
            }}
          />
          Invite to BSI Co-lab
        </Button>
      </Box>
      <Box
        sx={{
          mb: 1.5,
          pl: 0,
          pr: 1.5,
        }}
      >
        <Search>
          <StyledInputBase
            placeholder="Search for solution"
            inputProps={{ "aria-label": "search" }}
            value={searchParams}
            onChange={handleInputChange}
            onKeyDown={handleSearchSolutionProvider}
            autoFocus
          />
          {searchParams ? (
           <IconButton
            onClick={handleClearSearch}
            sx={{ position: "absolute", right: 0 }}
            >
            <CancelIcon sx={{ color: "lightSecondary.main" }} />
            </IconButton>
           ) : (
            <SearchIconWrapper>
            <SearchIcon sx={{ color: "lightSecondary.main" }} />
            </SearchIconWrapper>
         )}
        </Search>
      </Box>
      <List
        component="nav"
        sx={{
          mb: 0,
          "& .MuiButtonBase-root + .MuiButtonBase-root": {
            marginTop: "10px",
          },
          pl: 0,
          pr: 0.9,
          overflowY: openSolutionList ? "hidden" : "auto",
          maxHeight: "calc(100vh - 192px)",
          "&::-webkit-scrollbar": {
            width: "6px",
            borderRadius: "4px",
            display: "block",
            position: "absolute",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#9C9C9C",
            borderRadius: "4px",
          },
          [theme.breakpoints.down("lg")]: {
            maxHeight: "calc(100vh - 192px)",
          },
        }}
      >
        {solutionList?.solutions?.solutions?.map(
          (solution, index) =>
            index === index && (
              <ListItemButton
                key={solution.solution_id?._id}
                onClick={() =>
                  chatOpenHandle(
                    index,
                    solution?.solution_provider_id?._id,
                    solution?.solution_id?._id,
                    solution?.user_id?._id,
                    solution?.solution_id.solution_name,
                    `${logoBaseUrl}${solution.solution_id.logo}`,
                    solution?._id
                  )
                }
                sx={{
                  boxShadow: "none",
                  backgroundColor:  solutionId === solution?.solution_id?._id
                  ? "#d0e4f3" 
                  : "extraLTheme.main", 
                  borderRadius: "10px",
                  minHeight: "70px",
                  maxHeight: "100px",
                  display: "block",
                  "&:hover": {
                  backgroundColor: solutionId === solution?.solution_id?._id
                    ? "#d0e4f3"
                    : "extraLTheme.main", 
                },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 1.5,
                  }}
                >
                  <Box>
                    <img
                      style={{
                        width: "106px",
                        height: "30px",
                        mixBlendMode: "multiply",
                      }}
                      src={`${logoBaseUrl}${solution.solution_id.logo}`}
                      alt={`${solution.solution_id.solution_name} logo`}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      variant="subtitle1"
                      sx={{
                        fontSize: "0.95rem",
                        color: "ltheme.main",
                        fontWeight: "600",
                        // display: "flex",s
                        gap: "6px",
                        display: solutionId === solution?.solution_id?._id ? "none" : "flex"
                      }}
                    >
                      {/* <Badge color="secondary" badgeContent=" " variant="dot"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: "red.main"
                                        }
                                    }}
                                > */}
                      {/* <MessageOutlinedIcon
                        sx={{
                          verticalAlign: "middle",
                          fill: "ltheme.main",
                          width: "20px",
                          height: "20px",
                        }}
                      /> */}
                      <Typography
                        sx={{
                          verticalAlign: "middle",
                          fill: "ltheme.main",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        
                      </Typography>
                      {/* </Badge> */}
                    </Box>
                    {/* <div>
                                <Button
                                    id="basic-button"
                                    aria-controls={openDw ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openDw ? 'true' : undefined}
                                    onClick={handleClick}
                                    sx={{
                                        p: 0,
                                        minWidth: '10px',
                                        verticalAlign: '-5px'
                                    }}
                                >
                                    <MoreVertOutlinedIcon sx={{ color: "secondary.main" }} style={{ marginLeft: "0" }} />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openDw}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    sx={{
                                        '& .MuiMenu-paper': {
                                            borderRadius: "4px",
                                            border: "none",
                                            minWidth: "210px",
                                            p: 0.5,
                                        }
                                    }}
                                >
                                    <Paper
                                        sx={{
                                            width: 210,
                                            maxWidth: '100%',
                                            background: "#fff",
                                            borderRadius: "10px",
                                            borderBottom: "none",
                                            boxShadow: "none",
                                            p: 0.5
                                        }}>
                                        <MenuItem onClick={handleClose}
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                lineHeight: "20px",
                                                color: "lightSecondary.main",
                                                py: 1,
                                                px: 1.4,
                                                '&:hover': {
                                                    backgroundColor: 'extraLTheme.main'
                                                },
                                                '&.active': {
                                                    backgroundColor: 'primary.main',
                                                    color: "#fff",
                                                    borderRadius: "10px"
                                                }
                                            }}
                                        >Go to solution page</MenuItem>
                                        <MenuItem onClick={handleClose}
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                lineHeight: "20px",
                                                color: "lightSecondary.main",
                                                py: 1,
                                                px: 1.4,
                                                '&:hover': {
                                                    backgroundColor: 'extraLTheme.main'
                                                },
                                                '&.active': {
                                                    backgroundColor: 'primary.main',
                                                    color: "#fff",
                                                    borderRadius: "10px"
                                                }
                                            }}
                                        >Archive</MenuItem>
                                        <MenuItem onClick={handleClose}
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                lineHeight: "20px",
                                                color: "lightSecondary.main",
                                                py: 1,
                                                px: 1.4,
                                                '&:hover': {
                                                    backgroundColor: 'extraLTheme.main'
                                                },
                                                '&.active': {
                                                    backgroundColor: 'primary.main',
                                                    color: "#fff",
                                                    borderRadius: "10px"
                                                }
                                            }}
                                        >Mute</MenuItem>
                                        <Divider
                                            sx={{
                                                borderColor: 'ltheme.main',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                lineHeight: "20px",
                                                color: "lightSecondary.main",
                                                display: 'block',
                                                py: 1,
                                                px: 1.4,
                                                '&:hover': {
                                                    backgroundColor: 'extraLTheme.main'
                                                },
                                                '&.active': {
                                                    backgroundColor: 'primary.main',
                                                    color: "#fff",
                                                    borderRadius: "10px"
                                                }
                                            }}
                                        >Status: <br />

                                            <div>

                                                <Button
                                                    id="basic-button1"
                                                    aria-controls={openStatus ? 'basic-menu1' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openStatus ? 'true' : undefined}
                                                    onClick={handleStatusClick}
                                                    sx={{
                                                        p: '10px 0',
                                                        minWidth: '10px',
                                                        width: '100%',
                                                        verticalAlign: '-5px',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        color: 'secondary.main'
                                                    }}
                                                >
                                                    <div>Invited</div>
                                                    <ChevronRightOutlinedIcon sx={{ color: "secondary.main" }} style={{ marginLeft: "0" }} />
                                                </Button>
                                                <Menu
                                                    id="basic-menu1"
                                                    anchorEl={anchorElStatus}
                                                    open={openStatus}
                                                    onClose={handleStatusClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button1',
                                                    }}
                                                    sx={{
                                                        '& .MuiMenu-paper': {
                                                            borderRadius: "4px",
                                                            border: "none",
                                                            minWidth: "210px",
                                                            p: 0.5,
                                                        }
                                                    }}
                                                >
                                                    <Paper
                                                        sx={{
                                                            width: 210,
                                                            maxWidth: '100%',
                                                            background: "#fff",
                                                            borderRadius: "10px",
                                                            borderBottom: "none",
                                                            boxShadow: "none",
                                                            p: 0.5
                                                        }}>
                                                        <MenuItem onClick={handleStatusClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Invited</MenuItem>
                                                        <MenuItem onClick={handleStatusClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Shortlisted</MenuItem>
                                                        <MenuItem onClick={handleStatusClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Rejected</MenuItem>
                                                        <MenuItem onClick={handleStatusClose}
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                fontWeight: "600",
                                                                lineHeight: "20px",
                                                                color: "lightSecondary.main",
                                                                py: 1,
                                                                px: 1.4,
                                                                '&:hover': {
                                                                    backgroundColor: 'extraLTheme.main'
                                                                },
                                                                '&.active': {
                                                                    backgroundColor: 'primary.main',
                                                                    color: "#fff",
                                                                    borderRadius: "10px"
                                                                }
                                                            }}
                                                        >Selected</MenuItem>
                                                    </Paper>
                                                </Menu>
                                            </div>
                                        </Typography>
                                    </Paper>
                                </Menu>
                            </div> */}
                  </Box>
                </Box>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    color: solutionId === solution?.solution_id?._id? "black" : "black",
                  }}
                >
                  <Tooltip 
                     title=
                     {solution?.solution_id?.solution_name ? 
                      `${solution?.solution_id?.solution_name.charAt(0).toUpperCase()}${solution?.solution_id?.solution_name.slice(1)}` 
                      : ""
                    }
                     >
                      {solution?.solution_id?.solution_name? 
                        `${solution?.solution_id?.solution_name.charAt(0).toUpperCase()}${solution?.solution_id?.solution_name.slice(1)}` 
                        : ""
                      }
                    </Tooltip>
                </Typography>
              </ListItemButton>
            )
        )}

        {/* <ListItemButton
                    onClick={() => chatOpenHandle(2)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[2] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[2] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(3)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[3] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_155.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                27
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[3] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Blockpass Identity for a Connected World
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(4)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[4] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_153.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                14
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[4] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Claim Settlement Platform
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(5)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[5] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img style={{ maxWidth: '100px', display: "block" }} src={getImages('image_154.png')} />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: "red.main"
                                        }
                                    }}
                                >
                                    <MessageOutlinedIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fill: "ltheme.main",
                                            width: "20px",
                                            height: "20px"
                                        }}
                                    />
                                </Badge>
                                &nbsp;
                                13
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[5] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        CIAM - Customer Identity & Access Management
                    </Typography>

                </ListItemButton> */}
        {/* <ListItemButton
                    onClick={() => chatOpenHandle(6)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[6] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[6] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(1)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[1] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img style={{ maxWidth: '100px', display: "block" }} src={getImages('image_154.png')} />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: "red.main"
                                        }
                                    }}
                                >
                                    <MessageOutlinedIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fill: "ltheme.main",
                                            width: "20px",
                                            height: "20px"
                                        }}
                                    />
                                </Badge>
                                &nbsp;
                                13
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[1] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        CIAM - Customer Identity & Access Management
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(2)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[2] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[2] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(3)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[3] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_155.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                27
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[3] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Blockpass Identity for a Connected World
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(4)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[4] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_153.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                14
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[4] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Claim Settlement Platform
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(5)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[5] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img style={{ maxWidth: '100px', display: "block" }} src={getImages('image_154.png')} />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: "red.main"
                                        }
                                    }}
                                >
                                    <MessageOutlinedIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fill: "ltheme.main",
                                            width: "20px",
                                            height: "20px"
                                        }}
                                    />
                                </Badge>
                                &nbsp;
                                13
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[5] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        CIAM - Customer Identity & Access Management
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(6)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[6] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[6] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(1)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[1] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img style={{ maxWidth: '100px', display: "block" }} src={getImages('image_154.png')} />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: "red.main"
                                        }
                                    }}
                                >
                                    <MessageOutlinedIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fill: "ltheme.main",
                                            width: "20px",
                                            height: "20px"
                                        }}
                                    />
                                </Badge>
                                &nbsp;
                                13
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[1] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        CIAM - Customer Identity & Access Management
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(2)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[2] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[2] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(3)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[3] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_155.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                27
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[3] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Blockpass Identity for a Connected World
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(4)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[4] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_153.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                14
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[4] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Claim Settlement Platform
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(5)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[5] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img style={{ maxWidth: '100px', display: "block" }} src={getImages('image_154.png')} />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <Badge color="secondary" badgeContent=" " variant="dot"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: "red.main"
                                        }
                                    }}
                                >
                                    <MessageOutlinedIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fill: "ltheme.main",
                                            width: "20px",
                                            height: "20px"
                                        }}
                                    />
                                </Badge>
                                &nbsp;
                                13
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[5] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        CIAM - Customer Identity & Access Management
                    </Typography>

                </ListItemButton>
                <ListItemButton
                    onClick={() => chatOpenHandle(6)}
                    sx={{
                        boxShadow: 'none',
                        backgroundColor: 'extraLTheme.main',
                        borderRadius: "10px",
                        minHeight: "100px",
                        display: 'block',
                        border: openChat[6] ? 'solid 1px #106EED' : 'solid 1px #ECF6FF'
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1.5
                        }}>
                        <Box>
                            <img
                                style={{ maxWidth: '100px', display: "block" }}
                                src={getImages('image_53.png')}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontSize: "0.95rem",
                                    color: "ltheme.main",
                                    fontWeight: "600"
                                }}>
                                <MessageOutlinedIcon
                                    sx={{
                                        verticalAlign: "middle",
                                        fill: "ltheme.main",
                                        width: "20px",
                                        height: "20px"
                                    }}
                                />
                                &nbsp;
                                5
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant='body2'
                        sx={{
                            fontSize: "0.9rem",
                            color: openChat[6] ? 'primary.main' : 'ltheme.main'
                        }}
                    >
                        Advanced Solutions in Digital Healthcare
                    </Typography>

                </ListItemButton> */}
      </List>
      <Modals
        open={openInviteBsiCoLab}
        handleClose={closeInviteBsiCoLab}
        modalWidth={500}
      >
        <InvitetoBSIColab
          showToastMessage={showToastMessage}
          handleClose={closeInviteBsiCoLab}
          refetchTeamMemberList={refetchTeamMemberList}
        />
      </Modals>
    </>
  );
};

export default AllSolutionsList;
