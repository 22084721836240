import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  Badge,
  Button,
  IconButton,
  TextField,
  styled,
  Stack,
  AvatarGroup,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { getImages } from "../../commonComponents/const";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import { UserAddOutlined } from "@ant-design/icons";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CloseOutlined } from "@mui/icons-material";
import {
  setChatSelected,
  setOpenGroupChat,
  toggleOpenChatInfo,
  toggleOpenNewChat,
  toggleOpenSolutionList,
  toggleThreadChat,
} from "../../redux/features/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { useCreateGroupMutation } from "../../redux/api/Inbox/createGroup";
import { hideLoader, showLoader } from "../../redux/features/loader";
import { useSendMessageMutation } from "../../redux/api/Inbox/sendMessage";
import { toast } from "react-toastify";
import { useFetchGetMessageQuery } from "../../redux/api/Inbox/getMessage";
import socket from "./socket";
import FileUpload from "../BsiDrive/fileUpload";
import Modals from "../Modals";
import { setRefetchBusinessUserList, setRefetchSolutionList } from "../../redux/features/refetch";
const GroupChatContainer = ({ receiveMessages }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const messageBoxRef = useRef(null);
  const [groupCreated, setGroupCreated] = useState(false);

  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const userName = localStorage.getItem("userName");
  const userPic = localStorage.getItem("avatar");
  const solutionList = useSelector((state) => state.chat.openSolutionList);
  // const groupChatId = useSelector((state) => state.chat.groupChatId);
  const {
    solutionProviderId,
    solutionId,
    businessUserId,
    solutionName,
    businessName,
    businessLogo,
    solutionLogo,
    groupName,
    groupChatId,
  } = useSelector((state) => state.chat);
  const open = useSelector((state) => state.drawer.open);
  const [selectedUserForChat, setSelectUserForChat] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [sentMessage, setSentMessage] = useState(null);
  const [messages, setMessages] = useState([]);

  //   Download logic
  const handleDownload = async (documentUrl, documentName) => {
    try {
      // Fetch the file from the server
      const response = await fetch(documentUrl);

      // Check if the response is ok
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a blob from the response
      const blob = await response.blob();

      // Create a link element
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      // Set the href of the link to the blob URL
      link.href = url;

      // Choose the filename for the downloaded file
      link.download = documentName || "downloaded-file"; // Ensure documentName is a string

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up and remove the link from the DOM
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Free up memory
        showToastMessage("file downloaded", "success");
      }, 100);
    } catch (error) {
      console.error("Failed to download file:", error);
      showToastMessage("download failed", "success");
    }
  };

  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const date = new Date(sentMessage?.createdAt);
  const formattedTime = date.toLocaleTimeString("en-US", options);

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  const chatInfoHandle = () => {
    dispatch(toggleOpenChatInfo());
    if (!solutionList) {
      dispatch(toggleOpenSolutionList());
    }
  };
  const threadChatHandle = () => {
    dispatch(toggleThreadChat());
  };
  const closeChatContainer = () => {
    dispatch(setChatSelected(false));
    dispatch(setOpenGroupChat(false));
  };

  //   upload modal
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const handleOpenUploadModal = () => {
    setOpenUploadModal(true);
  };
  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const [
    createGroup,
    { isLoading: groupCreateLoading, isError, refetch: groupRefetch },
  ] = useCreateGroupMutation();
  const [
    sendMessages,
    { isLoading: sendMessageLoading, isError: sendMessageError },
  ] = useSendMessageMutation();

  const {
    data: messageData,
    error: messageDataError,
    isLoading: messageDataLoading,
    refetch: refetchMessages,
  } = useFetchGetMessageQuery({ groupChatId ,  userId});
  const [groupData, setGroupData] = useState(null);



  useEffect(() => {
    if (messageData) {
      setGroupData(messageData); // Set groupData when messages are available
      socket.emit("join chat", groupChatId); // Emit 'join chat' with groupChatId
    }
    refetchMessages();
    if(userType === "solution_provider"){
      dispatch(setRefetchBusinessUserList(true));
    }else{
      dispatch(setRefetchSolutionList(true));
    }
    
  }, [messageData, groupChatId]);

  // Pushing receive message in groupData
  useEffect(() => {
    if (receiveMessages && receiveMessages.length > 0) {
      setGroupData((prevGroupData) => {
        const existingMessages = prevGroupData?.data?.getGroupMessageData || [];
        // Map the received messages to the desired format
        const newMessages = receiveMessages.map((message) => {
          if (message.is_file === "Yes") {
            return {
              chat: message.chat._id,
              createdAt: message.createdAt,
              message: message.message,
              sender: {
                _id: message.sender._id,
                name: message?.sender?.name,
                pic: message?.sender?.pic || "",
              },
              is_file: "Yes",
              drive_ids: message.drive_ids.map((drive) => ({
                createdAt: drive.createdAt || new Date().toISOString(),
                isDeleted: drive.isDeleted,
                isDocument: drive.isDocument,
                name: drive.name || "", // Handling if the name is missing
                owner_id: drive.owner_id,
                parent_id: drive.parent_id || "null", // Default to "null" if no parent_id
                s3Url: drive.s3Url || "", // Default to empty string if no URL
                updatedAt: drive.updatedAt || new Date().toISOString(),
                __v: drive.__v || 0,
                _id: drive._id, // Unique document ID
              })),
              updatedAt: message.updatedAt,
              _id: message._id,
            };
          } else {
            return {
              chat: message.chat._id,
              createdAt: message.createdAt,
              message: message.message,
              sender: {
                _id: message.sender._id,
                name: message?.sender?.name,
                pic: message?.sender?.pic || "",
              },
              is_file: "No",
              updatedAt: message.updatedAt,
              _id: message._id,
            };
          }
        });
        return {
          ...prevGroupData,
          data: {
            ...prevGroupData?.data,
            getGroupMessageData: [
              ...existingMessages, // Keep the existing messages
              ...newMessages, // Add the new messages received via socket
            ],
          },
        };
      });
    }
    refetchMessages();
    // if(userType === "solution_provider"){
    //   dispatch(setRefetchBusinessUserList(true));
    // }else{
    //   dispatch(setRefetchSolutionList(true));
    // }
  }, [receiveMessages]);

  // useEffect(() => {
  //   if (
  //     userId &&
  //     businessUserId &&
  //     solutionName &&
  //     solutionProviderId &&
  //     solutionId
  //   ) {
  //     createGroupChat();
  //     dispatch(showLoader());
  //   } else {
  //     dispatch(hideLoader());
  //   }
  // }, [userId, solutionName, solutionProviderId, businessUserId, solutionId]);

  // const createGroupChat = async () => {
  //   try {
  //     const response = await createGroup({
  //       userId: userId,
  //       groupName: solutionName,
  //       solutionProviderId: solutionProviderId,
  //       businessUserId: businessUserId,
  //       solutionId: solutionId,
  //     }).unwrap();
  //     dispatch(showLoader());
  //     setGroupData(response?.data);
  //     setChatId(response?.data?.existingChat?._id);
  //     socket.emit("join chat", response?.data?.existingChat._id);
  //     setGroupCreated(true);
  //   } catch (error) {
  //   } finally {
  //     // Hide the loader when the API call is finished, regardless of the outcome
  //     dispatch(hideLoader());
  //   }
  // };

  const handleMessage = async () => {
    // Trim the userInput to remove leading and trailing spaces
    if (!userInput.trim()) {
      console.log("Message is empty, not sending.");
      return; // Exit the function if the input is empty or only contains whitespace
    }
    try {
      const response = await sendMessages({
        userId: userId,
        message: userInput,
        groupId: groupData?.data?.existingChat._id,
      }).unwrap();
      // Emit the message via Socket.IO
      // Construct the new message object
      const newMessageRec = {
        sender: {
          _id: userId,
          name: userName, // Replace with the actual user's name
          pic: userPic || "", // Replace with actual user's picture URL
        },
        chat: {
          _id: groupData?.data?.existingChat._id,
          chatName: groupData?.data?.existingChat?.chatName,
          isGroupChat: groupData?.data?.isGroupChat || true,
          users: groupData?.data?.existingChat?.users.map((user) => user._id),
        },
        is_file: "No",
        message: userInput,
        _id: response?.data?._id, // Replace with message ID from response
        createdAt: response?.data?.createdAt || new Date().toISOString(),
        updatedAt: response?.data?.updatedAt || new Date().toISOString(),
      };
      // Update groupData by adding the new message to the beginning of getGroupMessageData
      setGroupData((prevGroupData) => {
        const existingMessages = prevGroupData?.data?.getGroupMessageData || [];

        return {
          ...prevGroupData,
          data: {
            ...prevGroupData?.data,
            getGroupMessageData: [
              ...existingMessages, // Keep existing messages
              newMessageRec, // Add the newly sent message
            ],
          },
        };
      });
      socket.emit("new message", newMessageRec);
      setUserInput("");
      refetchMessages();
      // if(userType === "solution_provider"){
      //   dispatch(setRefetchBusinessUserList(true));
      // }else{
      //   dispatch(setRefetchSolutionList(true));
      // }
    } catch (error) {
      //   showToastMessage("Failed to schedule meeting", "error");
    } finally {
      // Hide the loader when the API call is finished, regardless of the outcome
      dispatch(hideLoader());
    }
  };

  // for scroll up when message sent
  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight; // Scroll to the bottom
    }
  }, [groupData]);

  useEffect(() => {
    if (messageDataLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [messageDataLoading, dispatch]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        {/* Chat header */}
        {groupData?.data?.existingChat && (
          <Box
            sx={{
              backgroundColor: "extraLTheme.main",
              borderRadius: "10px 10px 0 0",
              p: 1.2,
              display: "flex",
              alignItems: "center",
            }}
            key={groupData?.data?.existingChat._id}
          >
            {/* {[...(groupData?.existingChat?.users || [])]

              .sort((a, b) =>
                a._id === userId ? 1 : b._id === userId ? -1 : 0
              )
              .map((user, index) => {
                const avatarSrc = user.avatar ? user.avatar : null;
                const initials = `${user.first_name.charAt(0)}${
                  user.last_name?.charAt(0) || ""
                }`;

                
                return (
                  <Avatar
                    key={user._id}
                    alt={`${user.first_name} ${user.last_name}`}
                    sx={{
                      color: "#fff",
                      bgcolor: "lightSecondary.main",
                      width: index === 1 ? "50px" : "30px", 
                      height: index === 1 ? "50px" : "30px", 
                      ml: index !== 1 ? 1.5 : 0, 
                      mt: index !== 1 ? 2 : 0, 
                    }}
                    src={businessLogo}
                  >
                    {businessLogo} 
                  </Avatar>
                );
              })} */}
            {businessLogo ? (
              <Avatar
                sx={{
                  color: "#fff",
                  bgcolor: "lightSecondary.main",
                  width: "50px",
                  height: "50px",
                  maxWidth: "50px",
                  mr: 1,
                }}
                src={businessLogo}
              >
                {businessLogo && businessLogo !== ""
                  ? businessLogo
                  : groupName?.substring(0, 2)?.toUpperCase()}
              </Avatar>
            ) : (
              <Box>
                {solutionLogo && solutionLogo !== "" ? (
                  <img
                    style={{
                      width: "100px",
                      minWidth: "30px",
                      maxWidth: "100px",
                      height: "30px",
                      mixBlendMode: "multiply",
                    }}
                    src={solutionLogo}
                    alt={`${groupName?.charAt(0) || ""}`}
                  />
                ) : (
                  <Avatar
                    sx={{
                      color: "#fff",
                      bgcolor: "lightSecondary.main",
                      width: "50px",
                      height: "50px",
                      maxWidth: "50px",
                      mr: 1,
                    }}
                  >
                    {groupName?.substring(0, 2)?.toUpperCase()}
                  </Avatar>
                )}
              </Box>
            )}

            <ListItemText
              sx={{
                pl: 0.5, // Reduce padding between avatar and text
                width: "auto", // Adjust width to fit content
              }}
              primary={
                <Typography
                  noWrap
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "secondary.main",
                    lineHeight: "22px",
                    maxWidth: "220px",
                    [theme.breakpoints.down("sm")]: {
                      maxWidth: "110px",
                    },
                  }}
                  variant="h6"
                >
                  {groupName
                    ? `${groupName.charAt(0).toUpperCase()}${groupName.slice(
                        1
                      )}`
                    : ""}
                </Typography>
              }
              secondary={
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "primary.main",
                    pl: 0,
                  }}
                >
                  {[...(groupData?.data?.existingChat?.users || [])] // Create a shallow copy of the array
                    .slice() // Ensure the array is mutable by creating a new copy
                    .sort((a, b) =>
                      a._id === userId ? 1 : b._id === userId ? -1 : 0
                    ) // Sort to move the matching userId to the end
                    .map((user) => user.first_name)
                    .join(" , ")}
                </Typography>
              }
            />
            {/* <ListItemText
              sx={{
                width: "calc(40% - 56px)",
                textAlign: "right",
                [theme.breakpoints.down("md")]: {
                  whiteSpace: "nowrap",
                  minWidth: "120px",
                },
              }}
            >
              <IconButton
                sx={{
                  position: "relative",
                  [theme.breakpoints.down("lg")]: {
                    px: 0.5,
                  },
                }}
              >
                <UserAddOutlined
                  style={{
                    width: "22px",
                    height: "22px",
                    color: "#2A589C",
                  }}
                />
              </IconButton>
              <IconButton
                sx={{
                  position: "relative",
                  [theme.breakpoints.down("lg")]: {
                    px: 0.7,
                  },
                }}
              >
                <CalendarMonthIcon
                  sx={{
                    color: "lightSecondary.main",
                    fontSize: "24px",
                  }}
                />
                <LocalPhoneIcon
                  sx={{
                    color: "lightSecondary.main",
                    fontSize: "17px",
                    position: "absolute",
                    left: "4px",
                    bottom: "2px",
                  }}
                />
              </IconButton>
              <IconButton
                onClick={chatInfoHandle}
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    px: 0.5,
                  },
                }}
              >
                <InfoOutlinedIcon
                  sx={{
                    color: "lightSecondary.main",
                    fontSize: "24px",
                  }}
                />
              </IconButton>
              <IconButton
                onClick={closeChatContainer}
                sx={{
                  [theme.breakpoints.up("lg")]: {
                    display: "none",
                  },
                  [theme.breakpoints.down("lg")]: {
                    px: 0.2,
                  },
                }}
              >
                <CloseOutlined
                  sx={{
                    color: "lightSecondary.main",
                    fontSize: "24px",
                  }}
                />
              </IconButton>
            </ListItemText> */}
          </Box>
        )}
        {/* Chat Body */}
        <Box
          ref={messageBoxRef}
          sx={{
            position: "relative",
            overflowY: "auto",
            pr: 1,
            maxHeight: "calc(100vh - 265px)",
            minHeight: "calc(100vh - 267px)",
            "&::-webkit-scrollbar": {
              width: "6px",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#9C9C9C",
              borderRadius: "4px",
            },
            [theme.breakpoints.down("lg")]: {
              maxHeight: "calc(100vh - 224px)",
              minHeight: "calc(100vh - 226px)",
            },
            [theme.breakpoints.down("md")]: {
              maxHeight: "calc(100vh - 251px)",
              minHeight: "calc(100vh - 253px)",
            },
          }}
        >
          {/* Sender message */}
          {/* <Box
                        sx={{
                            textAlign: 'right',
                            pr: 2,
                            py: 2
                        }}>
                        <Box
                            sx={{
                                backgroundColor: 'lightSecondary.main',
                                borderRadius: '10px 0px 10px 10px',
                                p: "10px 15px",
                                width: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    right: '-16px',
                                    top: '0',
                                    borderLeft: 'solid 16px #2A589C',
                                    borderBottom: 'solid 16px transparent'
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#fff',
                                    fontWeight: '500'
                                }}
                            >Hello Team, I came across to your blockchain solution and Keen to know more about it. Actually I am working in fintech domain where we are planning to implement such solution.</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>
                        </Box>
                    </Box> */}
          {groupData?.data &&
            groupData?.data?.getGroupMessageData &&
            Array.isArray(groupData?.data?.getGroupMessageData) && (
              <>
                {[...groupData?.data?.getGroupMessageData]
                  .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                  .map((message , index) => (
                    <Box key={message._id} sx={{ py: 2 }}>
                      {message.sender._id === userId ? (
                        // Sender message
                        <Box
                          sx={{
                            textAlign: "right",
                            pr: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            gap: "3vh",
                            width: "100%",
                          }}
                        >
                          {/* New */}
                          {message?.is_file === "No" ? (
                            <Box
                              sx={{
                                backgroundColor: "lightSecondary.main",
                                borderRadius: "10px 0px 10px 10px",
                                p: "10px 15px",
                                width: "40%",
                                position: "relative",
                                textAlign: "left",
                                "&:before": {
                                  content: '""',
                                  position: "absolute",
                                  right: "-16px",
                                  top: "0",
                                  borderLeft: "solid 16px #2A589C",
                                  borderBottom: "solid 16px transparent",
                                },
                                [theme.breakpoints.down("lg")]: {
                                  width: "50%",
                                },
                                [theme.breakpoints.down("md")]: {
                                  width: "50%",
                                },
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "0.9rem",
                                  color: "#fff",
                                  fontWeight: "500",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {message?.message}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#fff",
                                  fontWeight: "500",
                                  textAlign: "right",
                                  marginTop: "6px",
                                }}
                              >
                                {`${new Date(
                                  message?.createdAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })} \u00A0 ${new Date(
                                  message?.createdAt
                                ).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })}`}
                              </Typography>
                            </Box>
                          ) : (
                            <>
                              {message?.drive_ids?.map((document, index) => {
                                const fileExtension = document.name
                                  ? document.name.split(".").pop().toLowerCase()
                                  : "";
                                const isImage = ["jpg", "jpeg", "png"].includes(
                                  fileExtension
                                );
                                const isPDF = fileExtension === "pdf";
                                const isGif = fileExtension === "gif";
                                const isDocx = fileExtension === "docx";
                                const isDoc = fileExtension === "doc";
                                const isMp4 = fileExtension === "mp4";
                                const isAvi = fileExtension === "avi";
                                const isMov = fileExtension === "mov";
                                const isWmv = fileExtension === "wmv";
                                const isMp3 = fileExtension === "mp3";
                                const isWav = fileExtension === "wav";
                                const isOgg = fileExtension === "ogg";
                                const xlsx = fileExtension === "xlsx";
                                const csv = fileExtension === "csv";

                                return isImage ? (
                                  // <Box
                                  //   sx={{
                                  //     backgroundColor: "lightSecondary.main",
                                  //     borderRadius: "10px 0px 10px 10px",
                                  //     p: "10px 15px",
                                  //     maxWidth: "40%",
                                  //     position: "relative",
                                  //     textAlign: "left",
                                  //     "&:before": {
                                  //       content: '""',
                                  //       position: "absolute",
                                  //       right: "-16px",
                                  //       top: "0",
                                  //       borderLeft: "solid 16px #2A589C",
                                  //       borderBottom: "solid 16px transparent",
                                  //     },
                                  //     [theme.breakpoints.down("md")]: {
                                  //       width: "calc(100% - 70px)",
                                  //     },
                                  //   }}
                                  // >
                                  //   <Box
                                  //     sx={{
                                  //       overflow: "hidden",
                                  //       display: "flex",
                                  //       alignItems: "center",
                                  //       justifyContent: "center",
                                  //       width: "10rem",
                                  //       height: "11rem",
                                  //       borderRadius: "10px",
                                  //     }}
                                  //   >
                                  //     <img
                                  //       src={document.s3Url} // Image path based on actual file name
                                  //       alt="image"
                                  //       style={{
                                  //         objectFit: "contain",
                                  //       }}
                                  //     />
                                  //   </Box>
                                  //   <Box>
                                  //     <Typography
                                  //       variant="body2"
                                  //       sx={{
                                  //         fontSize: "10px",
                                  //         color: "#fff",
                                  //         fontWeight: "500",
                                  //         textAlign: "right",
                                  //         marginTop: "6px",
                                  //       }}
                                  //     >
                                  //       {`${new Date(
                                  //         message?.createdAt
                                  //       ).toLocaleDateString("en-US", {
                                  //         year: "numeric",
                                  //         month: "short",
                                  //         day: "numeric",
                                  //       })} \u00A0 ${new Date(
                                  //         message?.createdAt
                                  //       ).toLocaleTimeString("en-US", {
                                  //         hour: "numeric",
                                  //         minute: "numeric",
                                  //         hour12: true,
                                  //       })}`}
                                  //     </Typography>
                                  //   </Box>
                                  // </Box>

                                  <Box
                                  key={document._id}
                                    sx={{
                                      backgroundColor: "lightSecondary.main",
                                      borderRadius: "10px 0px 10px 10px",
                                      p: "10px 15px",
                                      maxWidth: "60%",
                                      position: "relative",
                                      display: "inline-block",
                                      textAlign: "center",
                                      "&:before": {
                                        content: '""',
                                        position: "absolute",
                                        right: "-16px",
                                        top: "0",
                                        borderLeft: "solid 16px #2A589C",
                                        borderBottom: "solid 16px transparent",
                                      },
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        mt: 0.5,
                                      }}
                                    >
                                      <img
                                        style={{
                                          maxWidth: "130px",
                                          maxHeight: "11rem",
                                          borderRadius: "8px",
                                          objectFit: "contain",
                                        }}
                                        src={document.s3Url}
                                      />
                                    </Box>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "12px",
                                        color: "ltheme.main",
                                        fontWeight: "500",
                                        textAlign: "right",
                                      }}
                                    >
                                      {`${new Date(
                                        document?.createdAt
                                      ).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                      })} \u00A0 ${new Date(
                                        document?.createdAt
                                      ).toLocaleTimeString("en-US", {
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      })}`}
                                    </Typography>
                                  </Box>
                                ) : (
                                  // <Box
                                  //   sx={{
                                  //     backgroundColor: "lightSecondary.main",
                                  //     borderRadius: "10px 0px 10px 10px",
                                  //     p: "10px 15px",
                                  //     maxWidth: "40%",
                                  //     position: "relative",

                                  //     textAlign: "left",
                                  //     "&:before": {
                                  //       content: '""',
                                  //       position: "absolute",
                                  //       right: "-16px",
                                  //       top: "0",
                                  //       borderLeft: "solid 16px #2A589C",
                                  //       borderBottom: "solid 16px transparent",
                                  //     },
                                  //     [theme.breakpoints.down("md")]: {
                                  //       width: "calc(100% - 70px)",
                                  //     },
                                  //   }}
                                  // >
                                  //   <Box
                                  //     sx={{
                                  //       boxShadow: "none",
                                  //       backgroundColor: "#fff",
                                  //       borderRadius: "10px",
                                  //       width: "100%",
                                  //       minHeight: "5rem",
                                  //       display: "flex",
                                  //       alignItems: "center",
                                  //       p: "8px 12px 8px 12px",
                                  //       "&:hover": {
                                  //         backgroundColor: "#fff",
                                  //       },
                                  //       marginTop: "6px",
                                  //     }}
                                  //   >
                                  //     <Box
                                  //       sx={{
                                  //         borderRadius: "10px",
                                  //         overflow: "hidden",
                                  //         maxWidth: "20%",
                                  //         width: "64px",
                                  //         height: "64px",
                                  //         display: "flex",
                                  //         alignItems: "center",
                                  //         justifyContent: "center",
                                  //         marginRight: "12px",
                                  //         background: "#fff",
                                  //       }}
                                  //     >
                                  //       <Avatar
                                  //         src={
                                  //           isPDF
                                  //             ? getImages("pdfImage.webp") // Replace with your PDF icon path
                                  //             : isGif
                                  //             ? getImages("image-icon.webp")
                                  //             : isDocx
                                  //             ? getImages("docxIcon.png")
                                  //             : isDoc
                                  //             ? getImages("doc_icon.png")
                                  //             : isMp4
                                  //             ? getImages("mp4.png")
                                  //             : isAvi
                                  //             ? getImages("avi.png")
                                  //             : isMov
                                  //             ? getImages("mov.png")
                                  //             : isWav
                                  //             ? getImages("wav.png")
                                  //             : isMp3
                                  //             ? getImages("mp3.png")
                                  //             : isOgg
                                  //             ? getImages("ogg.png")
                                  //             : xlsx
                                  //             ? getImages("xlsx.png")
                                  //             : csv
                                  //             ? getImages("xlsx.png")
                                  //             : getImages("image-icon.webp") // Replace with your default icon path
                                  //         }
                                  //         sx={{
                                  //           width: "48px",
                                  //           height: "48px",
                                  //         }}
                                  //       />
                                  //     </Box>
                                  //     <Box
                                  //       sx={{
                                  //         display: "flex",
                                  //         width: "80%",
                                  //         maxWidth: "100%",
                                  //       }}
                                  //     >
                                  //       <Box
                                  //         sx={{
                                  //           display: "flex",
                                  //           flexDirection: "column",
                                  //           width: "100%",
                                  //           maxWidth: "100%",
                                  //           justifyContent: "flex-start",
                                  //         }}
                                  //       >
                                  //         <Typography
                                  //           noWrap
                                  //           variant="subtitle1"
                                  //           sx={{
                                  //             fontSize: "0.9rem",
                                  //             fontWeight: 600,
                                  //             color: "lightSecondary.main",
                                  //             width: "80%",
                                  //             maxWidth: "100%",
                                  //           }}
                                  //         >
                                  //           <Tooltip
                                  //             title={message?.drive_ids?.map(
                                  //               (document, index) => (
                                  //                 <span key={index}>
                                  //                   {document.name}
                                  //                 </span>
                                  //               )
                                  //             )}
                                  //           >
                                  //             {message?.drive_ids?.map(
                                  //               (document, index) => (
                                  //                 <span key={index}>
                                  //                   {document.name}
                                  //                 </span>
                                  //               )
                                  //             )}
                                  //           </Tooltip>
                                  //         </Typography>
                                  //         <Typography
                                  //           noWrap
                                  //           variant="body2"
                                  //           sx={{
                                  //             fontSize: "0.8rem",
                                  //             color: "ltheme.main",
                                  //             maxWidth: "100%",
                                  //           }}
                                  //         >
                                  //           {fileExtension}
                                  //         </Typography>
                                  //       </Box>
                                  //       <Box
                                  //         sx={{
                                  //           display: "flex",
                                  //           justifyContent: "flex-end",
                                  //           alignItems: "center",
                                  //           width: "20%",
                                  //           maxWidth: "100%",
                                  //         }}
                                  //       >
                                  //         <img
                                  //           src={getImages("download-icon.avif")}
                                  //           style={{
                                  //             width: "100%",
                                  //             height: "70%",
                                  //             fontWeight: "bold",
                                  //             maxWidth: "100%",
                                  //             maxHeight: "100%",
                                  //             cursor: "pointer",
                                  //             mixBlendMode: "multiply",
                                  //           }}
                                  //           onClick={() =>
                                  //             handleDownload(
                                  //               document.s3Url,
                                  //               document.name
                                  //             )
                                  //           }
                                  //         />
                                  //       </Box>
                                  //     </Box>
                                  //   </Box>
                                  //   <Typography
                                  //     variant="body2"
                                  //     sx={{
                                  //       fontSize: "12px",
                                  //       color: "#fff",
                                  //       fontWeight: "500",
                                  //       textAlign: "right",
                                  //       marginTop: "6px",
                                  //     }}
                                  //   >
                                  //     {`${new Date(
                                  //       message?.createdAt
                                  //     ).toLocaleDateString("en-US", {
                                  //       year: "numeric",
                                  //       month: "short",
                                  //       day: "numeric",
                                  //     })} \u00A0 ${new Date(
                                  //       message?.createdAt
                                  //     ).toLocaleTimeString("en-US", {
                                  //       hour: "numeric",
                                  //       minute: "numeric",
                                  //       hour12: true,
                                  //     })}`}
                                  //   </Typography>
                                  // </Box>

                                  // new

                                  <Box
                                  key={document._id}
                                    sx={{
                                      backgroundColor: "lightSecondary.main",
                                      borderRadius: "10px 0px 10px 10px",
                                      p: "10px 15px",
                                      width: open ? "45%" : "45%",
                                      position: "relative",
                                      display: "inline-block",
                                      textAlign: "left",
                                      "&:before": {
                                        content: '""',
                                        position: "absolute",
                                        right: "-16px",
                                        top: "0",
                                        borderLeft: "solid 16px #2A589C",
                                        borderBottom: "solid 16px transparent",
                                      },

                                      [theme.breakpoints.down("lg")]: {
                                        width: "50%",
                                      },
                                      [theme.breakpoints.down("md")]: {
                                        width: "65%",
                                      },
                                    }}
                                  >
                                    <Stack
                                      direction="row"
                                      sx={{
                                        my: 0.5,
                                        // backgroundColor: '#DBEBF8',
                                        backgroundColor: "#fff",
                                        p: 2,
                                        borderRadius: "10px",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <Stack
                                        direction="row"
                                        sx={{
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          width: "80%",
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <ListItemAvatar
                                          sx={{
                                            textAlign: "center",
                                            backgroundColor: "#fff",
                                            width: "20%",
                                            minWidth: "50px",
                                            borderRadius: "10px",
                                            height: "50px",
                                            lineHeight: "50px",
                                            position: "relative",
                                          }}
                                        >
                                          <img
                                            style={{
                                              maxWidth: "40px",
                                              maxHeight: "40px",
                                              position: "absolute",
                                              left: "50%",
                                              top: "50%",
                                              transform:
                                                "translate(-50%, -50%)",
                                            }}
                                            src={
                                              isPDF
                                                ? getImages("pdfImage.webp") // Replace with your PDF icon path
                                                : isGif
                                                ? getImages("image-icon.webp")
                                                : isDocx
                                                ? getImages("docxIcon.png")
                                                : isDoc
                                                ? getImages("doc_icon.png")
                                                : isMp4
                                                ? getImages("mp4.png")
                                                : isAvi
                                                ? getImages("avi.png")
                                                : isMov
                                                ? getImages("mov.png")
                                                : isWav
                                                ? getImages("wav.png")
                                                : isMp3
                                                ? getImages("mp3.png")
                                                : // : isWav
                                                // ? getImages("wav.png")
                                                isOgg
                                                ? getImages("ogg.png")
                                                : xlsx
                                                ? getImages("xlsx.png")
                                                : csv
                                                ? getImages("xlsx.png")
                                                : getImages("image-icon.webp") // Replace with your default icon path
                                            }
                                          />
                                        </ListItemAvatar>
                                        <Stack
                                          sx={{
                                            pl: 2,
                                            width: "80%",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <Typography
                                            noWrap
                                            sx={{
                                              fontSize: "0.9rem",
                                              fontWeight: "600",
                                              color: "secondary.main",
                                              lineHeight: "22px",
                                              mb: 0.5,
                                            }}
                                            variant="subtitle1"
                                          >
                                            <Tooltip
                                              title={document.name}
                                            >
                                              {document.name}
                                            </Tooltip>
                                          </Typography>
                                          <Typography
                                            noWrap
                                            variant="body2"
                                            sx={{
                                              fontSize: "0.8rem",
                                              fontWeight: "500",
                                              color: "ltheme.main",
                                              width: "100%",
                                            }}
                                          >
                                            {fileExtension}
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                      <IconButton
                                        sx={{
                                          width: "20%",
                                          maxWidth: "100%",
                                          display:"flex",
                                          justifyContent:'flex-end',
                                          backgroundColor: "transparent", // Ensure no background color by default
                                          "&:hover": {
                                            backgroundColor: "transparent", // Prevent background color change on hover
                                          },
                                        }}
                                        onClick={() =>
                                          handleDownload(
                                            document.s3Url,
                                            document.name
                                          )
                                        }
                                      >
                                        <FileDownloadOutlinedIcon
                                          sx={{
                                            color: "lightSecondary.main",
                                          }}
                                        />
                                      </IconButton>
                                    </Stack>

                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "12px",
                                        color: "ltheme.main",
                                        fontWeight: "500",
                                        textAlign: "right",
                                      }}
                                    >
                                      {`${new Date(
                                        document?.createdAt
                                      ).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                      })} \u00A0 ${new Date(
                                        document?.createdAt
                                      ).toLocaleTimeString("en-US", {
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      })}`}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                        </Box>
                      ) : (
                        // Receiver message
                        <Box
                          sx={{
                            textAlign: "left",
                            pl: 2,
                            // py: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: "2vh",
                          }}
                        >
                          {message?.is_file === "No" ? (
                            <Box
                              sx={{
                                display: "flex",
                              }}
                            >
                              <ListItemAvatar
                                sx={{
                                  width: "42px",
                                }}
                              >
                                <Avatar
                                  sx={{
                                    color: "#fff",
                                    bgcolor: "lightSecondary.main",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                  src={message?.sender?.avatar}
                                >
                                  {!message?.sender?.avatar &&
                                    `${
                                      message?.sender?.first_name
                                        ?.charAt(0)
                                        .toUpperCase() || ""
                                    }${
                                      message?.sender?.last_name
                                        ?.charAt(0)
                                        .toUpperCase() || ""
                                    }`}
                                </Avatar>
                              </ListItemAvatar>
                              <Box
                                sx={{
                                  backgroundColor: "extraLTheme.main",
                                  borderRadius: "0px 10px 10px 10px",
                                  p: "10px 15px",
                                  width: "40%",
                                  position: "relative",
                                  display: "inline-block",
                                  textAlign: "left",
                                  "&:before": {
                                    content: '""',
                                    position: "absolute",
                                    left: "-16px",
                                    top: "0",
                                    borderRight: "solid 16px #ECF6FF",
                                    borderBottom: "solid 16px transparent",
                                  },
                                  [theme.breakpoints.down("lg")]: {
                                    width: "50%",
                                  },
                                  [theme.breakpoints.down("md")]: {
                                    width: "50%",
                                  },
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "0.9rem",
                                    color: "#419C2A",
                                    fontWeight: "600",
                                  }}
                                >
                                  {message?.sender?.first_name &&
                                  message?.sender?.last_name
                                    ? `${message.sender.first_name} ${message.sender.last_name}`
                                    : message?.sender?.name}
                                </Typography>

                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "0.9rem",
                                    color: "lightSecondary.main",
                                    fontWeight: "500",
                                    mt: 0.5,
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {message?.message}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "12px",
                                    color: "ltheme.main",
                                    fontWeight: "500",
                                    textAlign: "right",
                                    marginTop: "6px",
                                  }}
                                >
                                  {`${new Date(
                                    message?.createdAt
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })} \u00A0 ${new Date(
                                    message?.createdAt
                                  ).toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  })}`}
                                </Typography>
                              </Box>
                            </Box>
                          ) : (
                            <>
                              {message?.drive_ids?.map((document, index) => {
                                const fileExtension = document.name
                                  ? document.name.split(".").pop().toLowerCase()
                                  : "";
                                const isImage = ["jpg", "jpeg", "png"].includes(
                                  fileExtension
                                );
                                const isPDF = fileExtension === "pdf";
                                const isGif = fileExtension === "gif";
                                const isDocx = fileExtension === "docx";
                                const isDoc = fileExtension === "doc";
                                const isMp4 = fileExtension === "mp4";
                                const isAvi = fileExtension === "avi";
                                const isMov = fileExtension === "mov";
                                const isWmv = fileExtension === "wmv";
                                const isMp3 = fileExtension === "mp3";
                                const isWav = fileExtension === "wav";
                                const isOgg = fileExtension === "ogg";
                                const xlsx = fileExtension === "xlsx";
                                const csv = fileExtension === "csv";

                                return isImage ? (
                                  // <Box
                                  //   sx={{
                                  //     display: "flex",
                                  //   }}
                                  // >
                                  //   <ListItemAvatar
                                  //     sx={{
                                  //       width: "42px",
                                  //     }}
                                  //   >
                                  //     <Avatar
                                  //       sx={{
                                  //         color: "#fff",
                                  //         bgcolor: "lightSecondary.main",
                                  //         width: "40px",
                                  //         height: "40px",
                                  //       }}
                                  //       src={message?.sender?.avatar}
                                  //     >
                                  //       {!message?.sender?.avatar &&
                                  //         `${
                                  //           message?.sender?.first_name
                                  //             ?.charAt(0)
                                  //             .toUpperCase() || ""
                                  //         }${
                                  //           message?.sender?.last_name
                                  //             ?.charAt(0)
                                  //             .toUpperCase() || ""
                                  //         }`}
                                  //     </Avatar>
                                  //   </ListItemAvatar>
                                  //   <Box
                                  //     sx={{
                                  //       backgroundColor: "extraLTheme.main",
                                  //       borderRadius: "0px 10px 10px 10px",
                                  //       p: "10px 15px",
                                  //       width: "12rem",
                                  //       height: "14.375rem",
                                  //       // maxHeight:"15.375rem",
                                  //       position: "relative",
                                  //       display: "inline-block",
                                  //       textAlign: "left",
                                  //       "&:before": {
                                  //         content: '""',
                                  //         position: "absolute",
                                  //         left: "-16px",
                                  //         top: "0",
                                  //         borderRight: "solid 16px #ECF6FF",
                                  //         borderBottom: "solid 16px transparent",
                                  //       },
                                  //       [theme.breakpoints.down("md")]: {
                                  //         width: "calc(100% - 70px)",
                                  //       },
                                  //     }}
                                  //   >
                                  //     <Typography
                                  //       variant="body2"
                                  //       sx={{
                                  //         fontSize: "0.9rem",
                                  //         color: "#419C2A",
                                  //         fontWeight: "600",
                                  //       }}
                                  //     >
                                  //       {message?.sender?.first_name &&
                                  //       message?.sender?.last_name
                                  //         ? `${message.sender.first_name} ${message.sender.last_name}`
                                  //         : message?.sender?.name}
                                  //     </Typography>
                                  //     <Box
                                  //       sx={{
                                  //         overflow: "hidden",
                                  //         display: "flex",
                                  //         alignItems: "center",
                                  //         justifyContent: "center",
                                  //         // marginRight: "12px",
                                  //         // background: "#fff",
                                  //         width: "10rem",
                                  //         height: "11rem",
                                  //         borderRadius: "10px",
                                  //       }}
                                  //     >
                                  //       <img
                                  //         src={document.s3Url} // Image path based on actual file name
                                  //         alt="image"
                                  //         style={{
                                  //           objectFit: "contain",
                                  //         }}
                                  //       />
                                  //     </Box>
                                  //     <Box>
                                  //       <Typography
                                  //         variant="body2"
                                  //         sx={{
                                  //           fontSize: "10px",
                                  //           color: "ltheme.main",
                                  //           fontWeight: "500",
                                  //           textAlign: "right",
                                  //           marginTop: "6px",
                                  //         }}
                                  //       >
                                  //         {`${new Date(
                                  //           message?.createdAt
                                  //         ).toLocaleDateString("en-US", {
                                  //           year: "numeric",
                                  //           month: "short",
                                  //           day: "numeric",
                                  //         })} \u00A0 ${new Date(
                                  //           message?.createdAt
                                  //         ).toLocaleTimeString("en-US", {
                                  //           hour: "numeric",
                                  //           minute: "numeric",
                                  //           hour12: true,
                                  //         })}`}
                                  //       </Typography>
                                  //     </Box>
                                  //   </Box>
                                  // </Box>

                                  <Box
                                  key={document._id}
                                    sx={{
                                      textAlign: "left",
                                      // pl: 2,
                                      // py: 2,
                                      display: "flex",
                                    }}
                                  >
                                    <ListItemAvatar
                                      sx={{
                                        width: "42px",
                                      }}
                                    >
                                      <Avatar
                                        sx={{
                                          color: "success.main",
                                          bgcolor: "success.lighter",
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        src={message?.sender?.avatar}
                                      >
                                        {!message?.sender?.avatar &&
                                          `${
                                            message?.sender?.first_name
                                              ?.charAt(0)
                                              .toUpperCase() || ""
                                          }${
                                            message?.sender?.last_name
                                              ?.charAt(0)
                                              .toUpperCase() || ""
                                          }`}
                                      </Avatar>
                                    </ListItemAvatar>
                                    <Box
                                      sx={{
                                        backgroundColor: "extraLTheme.main",
                                        borderRadius: "0px 10px 10px 10px",
                                        p: "10px 15px",
                                        maxWidth: "60%",
                                        position: "relative",
                                        display: "inline-block",
                                        "&:before": {
                                          content: '""',
                                          position: "absolute",
                                          left: "-16px",
                                          top: "0",
                                          borderRight: "solid 16px #ECF6FF",
                                          borderBottom:
                                            "solid 16px transparent",
                                        },
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "0.9rem",
                                          color: "#419C2A",
                                          fontWeight: "600",
                                          textAlign: "left",
                                        }}
                                      >
                                        {message?.sender?.first_name &&
                                        message?.sender?.last_name
                                          ? `${message.sender.first_name} ${message.sender.last_name}`
                                          : message?.sender?.name}
                                      </Typography>
                                      <Box
                                        sx={{
                                          mt: 0.5,
                                          textAlign: "center",
                                        }}
                                      >
                                        <img
                                          style={{
                                            maxWidth: "120px",
                                            borderRadius: "8px",
                                            objectFit: "contain",
                                            mixBlendMode: "multiply",
                                          }}
                                          src={document.s3Url}
                                        />
                                      </Box>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "12px",
                                          color: "ltheme.main",
                                          fontWeight: "500",
                                          textAlign: "right",
                                        }}
                                      >
                                        {`${new Date(
                                          document?.createdAt
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })} \u00A0 ${new Date(
                                          document?.createdAt
                                        ).toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}`}
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box
                                  key={document._id}
                                    sx={{
                                      textAlign: "left",
                                      // pl: 2,
                                      // py: 2,
                                      display: "flex",
                                    }}
                                  >
                                    <ListItemAvatar
                                      sx={{
                                        width: "42px",
                                      }}
                                    >
                                      <Avatar
                                        sx={{
                                          color: "success.main",
                                          bgcolor: "success.lighter",
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        src={message?.sender?.avatar}
                                      >
                                        {!message?.sender?.avatar &&
                                          `${
                                            message?.sender?.first_name
                                              ?.charAt(0)
                                              .toUpperCase() || ""
                                          }${
                                            message?.sender?.last_name
                                              ?.charAt(0)
                                              .toUpperCase() || ""
                                          }`}
                                      </Avatar>
                                    </ListItemAvatar>
                                    <Box
                                      sx={{
                                        backgroundColor: "extraLTheme.main",
                                        borderRadius: "0px 10px 10px 10px",
                                        p: "10px 15px",
                                        width: open ? "45%" : "45%",
                                        position: "relative",
                                        display: "inline-block",
                                        textAlign: "left",
                                        "&:before": {
                                          content: '""',
                                          position: "absolute",
                                          left: "-16px",
                                          top: "0",
                                          borderRight: "solid 16px #ECF6FF",
                                          borderBottom:
                                            "solid 16px transparent",
                                        },
                                        [theme.breakpoints.down("lg")]: {
                                          width: "50%",
                                        },
                                        [theme.breakpoints.down("md")]: {
                                          width: "65%",
                                        },
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "0.9rem",
                                          color: "#419C2A",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {message?.sender?.first_name &&
                                        message?.sender?.last_name
                                          ? `${message.sender.first_name} ${message.sender.last_name}`
                                          : message?.sender?.name}
                                      </Typography>
                                      <Stack
                                        direction="row"
                                        sx={{
                                          my: 0.5,
                                          backgroundColor: "#DBEBF8",
                                          p: 2,
                                          borderRadius: "10px",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          maxWidth: "100%",
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          sx={{
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "80%",
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <ListItemAvatar
                                            sx={{
                                              textAlign: "center",
                                              backgroundColor: "#fff",
                                              minWidth: "50px",
                                              width: "20%",
                                              borderRadius: "10px",
                                              height: "50px",
                                              lineHeight: "50px",
                                              position: "relative",
                                            }}
                                          >
                                            <img
                                              style={{
                                                maxWidth: "40px",
                                                maxHeight: "40px",
                                                position: "absolute",
                                                left: "50%",
                                                top: "50%",
                                                transform:
                                                  "translate(-50%, -50%)",
                                              }}
                                              src={
                                                isPDF
                                                  ? getImages("pdfImage.webp") // Replace with your PDF icon path
                                                  : isGif
                                                  ? getImages("image-icon.webp")
                                                  : isDocx
                                                  ? getImages("docxIcon.png")
                                                  : isDoc
                                                  ? getImages("doc_icon.png")
                                                  : isMp4
                                                  ? getImages("mp4.png")
                                                  : isAvi
                                                  ? getImages("avi.png")
                                                  : isMov
                                                  ? getImages("mov.png")
                                                  : isWav
                                                  ? getImages("wav.png")
                                                  : isMp3
                                                  ? getImages("mp3.png")
                                                  : // : isWav
                                                  // ? getImages("wav.png")
                                                  isOgg
                                                  ? getImages("ogg.png")
                                                  : xlsx
                                                  ? getImages("xlsx.png")
                                                  : csv
                                                  ? getImages("xlsx.png")
                                                  : getImages("image-icon.webp") // Replace with your default icon path
                                              }
                                            />
                                          </ListItemAvatar>
                                          <Stack
                                            sx={{
                                              pl: 2,
                                              width: "80%",
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <Typography
                                              noWrap
                                              sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                                color: "secondary.main",
                                                lineHeight: "22px",
                                                mb: 0.5,
                                              }}
                                              variant="subtitle1"
                                            >
                                              <Tooltip
                                                title={message?.drive_ids?.map(
                                                  (document, index) => (
                                                    <span key={index}>
                                                      {document.name}
                                                    </span>
                                                  )
                                                )}
                                              >
                                                {message?.drive_ids?.map(
                                                  (document, index) => (
                                                    <span key={index}>
                                                      {document.name}
                                                    </span>
                                                  )
                                                )}
                                              </Tooltip>
                                            </Typography>
                                            <Typography
                                              noWrap
                                              variant="body2"
                                              sx={{
                                                fontSize: "0.8rem",
                                                fontWeight: "500",
                                                color: "ltheme.main",
                                                width: "100%",
                                              }}
                                            >
                                              {fileExtension}
                                            </Typography>
                                          </Stack>
                                        </Stack>
                                        <IconButton
                                          sx={{
                                            width: "20%",
                                            maxWidth: "100%",
                                            display:"flex",
                                            justifyContent:'flex-end',
                                            backgroundColor: "transparent", // Ensure no background color by default
                                            "&:hover": {
                                              backgroundColor: "transparent", // Prevent background color change on hover
                                            },
                                          }}
                                          onClick={() =>
                                            handleDownload(
                                              document.s3Url,
                                              document.name
                                            )
                                          }
                                        >
                                          <FileDownloadOutlinedIcon
                                            sx={{
                                              color: "lightSecondary.main",
                                            }}
                                          />
                                        </IconButton>
                                      </Stack>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontSize: "12px",
                                          color: "ltheme.main",
                                          fontWeight: "500",
                                          textAlign: "right",
                                        }}
                                      >
                                        {`${new Date(
                                          document?.createdAt
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })} \u00A0 ${new Date(
                                          document?.createdAt
                                        ).toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}`}
                                      </Typography>
                                    </Box>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  ))}
              </>
            )}

          {/* <Box
                        sx={{
                            textAlign: 'left',
                            pl: 2,
                            py: 2,
                            display: 'flex'
                        }}>
                        <ListItemAvatar
                            sx={{
                                width: "42px"
                            }}
                        >
                            <Avatar
                                sx={{
                                    color: 'success.main',
                                    bgcolor: 'success.lighter',
                                    width: '40px',
                                    height: '40px'
                                }}
                                src={getImages('1.jpg')}
                            >
                            </Avatar>
                        </ListItemAvatar>
                        <Box
                            sx={{
                                backgroundColor: 'extraLTheme.main',
                                borderRadius: '0px 10px 10px 10px',
                                p: "10px 15px",
                                maxWidth: '60%',
                                position: 'relative',
                                display: 'inline-block',
                                textAlign: 'left',
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '-16px',
                                    top: '0',
                                    borderRight: 'solid 16px #ECF6FF',
                                    borderBottom: 'solid 16px transparent'
                                },
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: '100%',
                                    width: 'calc(100% - 70px)',
                                }
                            }}
                        >
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: '#9C2A2A',
                                    fontWeight: '600'
                                }}>
                                Vish Strophobia
                            </Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '0.9rem',
                                    color: 'lightSecondary.main',
                                    fontWeight: '500',
                                    mt: 0.5
                                }}
                            >Yes I’m available.</Typography>
                            <Typography variant='body2'
                                sx={{
                                    fontSize: '12px',
                                    color: 'ltheme.main',
                                    fontWeight: '500',
                                    textAlign: 'right'
                                }}
                            >11:48 AM</Typography>

                            <Box
                                sx={{
                                    backgroundColor: '#ECF6FF',
                                    border: 'solid 1px #A2C2F1',
                                    borderRadius: '10px',
                                    padding: '0 7px',
                                    minWidth: '36px',
                                    minHeight: '36px',
                                    position: 'absolute',
                                    right: '-50px',
                                    top: '-15px',
                                    boxShadow: '0 5px 8px rgba(7, 57, 133, 0.1)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    [theme.breakpoints.down('md')]: {
                                        right: '-10px',
                                    }
                                }}
                            >
                                <IconButton
                                    sx={{
                                        p: 0.7
                                    }}
                                >
                                    <img style={{ maxWidth: '130px' }} src={getImages('emoji-icons.png')} />
                                </IconButton>
                                <IconButton
                                    variant="contained"
                                    sx={{
                                        p: 0.4,
                                        backgroundColor: '#DBEBF8'
                                    }}
                                >
                                    <AddOutlinedIcon
                                        sx={{
                                            color: '#2A589C'
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box> */}
        </Box>
        {/* Chat Footer */}
        <Box
          sx={{
            backgroundColor: "extraLTheme.main",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "ltheme.main",
            p: 0.5,
            borderRadius: "0 0 10px 10px",
            display: "flex",
            alignItems: "center",
            marginTop: "25px",
            justifyContent: "space-between",
            height: "50px",
            maxHeight: "50px",
          }}
        >
          {/* <Button
            variant="none"
            sx={{
              textTransform: "inherit",
              p: "8px 8px",
              minWidth: "inherit",
              boxShadow: "none",
            }}
          >
            <SentimentSatisfiedAltIcon
              sx={{
                color: "lightSecondary.main",
                fontSize: "20px",
              }}
            />
          </Button> */}
          <Button
            variant="none"
            sx={{
              textTransform: "inherit",
              p: "8px 8px",
              minWidth: "inherit",
              boxShadow: "none",
            }}
          >
            <AttachFileIcon
              onClick={handleOpenUploadModal}
              sx={{
                color: "lightSecondary.main",
                fontSize: "20px",
              }}
            />
          </Button>
          <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            placeholder="Type a message"
            disabled={sendMessageLoading}
            value={userInput}
            multiline
            minRows={1} // Start with 2 rows
            maxRows={2} // Limit to 3 rows
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.shiftKey) {
                e.preventDefault();
                setUserInput((prev) => prev + "\n"); // Add new line on Shift + Enter
              } else if (
                e.key === "Enter" &&
                !e.shiftKey &&
                !sendMessageLoading
              ) {
                e.preventDefault();
                handleMessage(); // Send the message when Enter is pressed without Shift
              }
            }}
            onChange={(e) => {
              setUserInput(e.target.value);
            }}
            sx={{
              width: "calc(100% - 153px)",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiInputBase-input": {
                padding: "0px 10px",
                fontSize: "0.95rem",
                color: "lightSecondary.main",
                fontWeight: "600",
                overflowY: "auto",
                maxHeight: "100px", // Limit the height of the input area
                // Apply custom scrollbar styles
                "&::-webkit-scrollbar": {
                  width: "6px",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#9C9C9C",
                  borderRadius: "4px",
                },
              },
            }}
          />
          {/* <Button
            variant="none"
            sx={{
              textTransform: "inherit",
              p: "8px 8px",
              minWidth: "inherit",
              boxShadow: "none",
              borderRadius: "0",
              borderRight: "solid 1px",
              borderRightColor: "lightSecondary.main",
            }}
          >
            <MicIcon
              sx={{
                color: "lightSecondary.main",
                fontSize: "20px",
              }}
            />
          </Button> */}
          <Button
            variant="contained"
            disabled={sendMessageLoading}
            onClick={handleMessage}
            sx={{
              textTransform: "inherit",
              p: "8px 8px",
              backgroundColor: "lightSecondary.main",
              minWidth: "inherit",
              boxShadow: "none",
              // ml: 1,
              justifySelf: "flex-end",
            }}
          >
            <SendIcon
              sx={{
                color: "#fff",
                fontSize: "15px",
              }}
            />
          </Button>
        </Box>
      </Box>
      <Modals
        open={openUploadModal}
        handleClose={handleCloseUploadModal}
        modalWidth={500}
      >
        <FileUpload
          handleClose={handleCloseUploadModal}
          handleCloseUploadModal={handleCloseUploadModal}
          showToastMessage={showToastMessage}
          groupData={groupData}
          setGroupData={setGroupData}
          userInput={userInput}
          setUserInput={setUserInput}
          groupChatId={groupChatId}
        />
      </Modals>
    </>
  );
};

export default GroupChatContainer;
